import React, { useEffect, useState, useMemo } from 'react';
import { connect } from 'react-redux';
import get from 'lodash/get';
import * as Themes from 'common/dist/lib/themes';

import Plugins from './Plugins';
import { Page } from './types';
import { RootState } from 'store';
import { detectMissingFonts } from 'helpers/fontDetector';

import './style.css';

const ThemeComponent = Themes.DefaultTheme;

const mapState = (state: RootState) => ({
  logoURL: state.site.logoURL,
  pages: state.site.pages,
  label: state.site.displayLabel,
  plugins: state.site.plugins,
  backgroundColor: state.site.backgroundColor,
  useLogoOriginalSize: state.site.options?.useLogoOriginalSize,
  memberId: state.app.memberId,
  options: state.site.options,
});

interface StateProps {
  logoURL: string;
  pages: Page[];
  plugins: any;
  label: string;
  memberId: string;
  backgroundColor: string;
  useLogoOriginalSize: string;
  options: Record<string, string>;
}

function Site({
  logoURL,
  pages,
  label,
  plugins,
  memberId,
  backgroundColor,
  useLogoOriginalSize,
  options,
}: StateProps) {
  const fonts = options?.fonts || '';
  const [missingFonts, setMissingFonts] = useState<string[]>([]);
  const [page, setPage] = useState({} as any);
  const [filteredPlugins, setPlugins] = useState([] as any);
  const columns = parseInt(get(page, ['layoutTemplate'], 0), 10);
  const sortedPages = useMemo(() => {
    return pages.sort((left: Page, right: Page) => {
      if (left.order === undefined) {
        return 1;
      } else if (right.order === undefined) {
        return -1;
      } else if (left.order === right.order) {
        return 0;
      }

      return left.order > right.order ? 1 : -1;
    });
  }, [JSON.stringify(pages)]);

  useEffect(() => {
    const fontsList = String(fonts).split('|');
    detectMissingFonts(fontsList).then((fonts) => setMissingFonts(fonts));
  }, [fonts]);

  useEffect(() => {
    const currentPageName: string = get(page, 'name', '');
    const currentPageIndex: any = sortedPages.findIndex(
      (item: Page) => item.name === currentPageName
    );

    setPage(sortedPages[currentPageIndex > -1 ? currentPageIndex : 0] || page);
  }, [page, sortedPages]);

  useEffect(() => {
    if (page.plugins) {
      setPlugins(
        page.plugins
          .map((name: string) => plugins[name])
          .filter((plugin: any) => !!plugin)
      );
    }
  }, [page, JSON.stringify(plugins)]);

  function handleNavClick(event: React.SyntheticEvent<EventTarget>) {
    if (!(event.target instanceof HTMLAnchorElement)) {
      return;
    }

    const nextPageId = event.target.dataset.id as string;
    const nextPage = sortedPages.find((item: Page) => item.name === nextPageId);

    setPage(nextPage);
  }

  function handleSetPage(page: string) {
    const nextPage = sortedPages.find((item: Page) => item.name === page);

    if (nextPage) {
      setPage(nextPage);
    } else {
      console.error('Page not found!');
    }
  }

  return (
    <div
      className="site-container"
      style={{ backgroundColor: backgroundColor || '#FFF' }}
    >
      {missingFonts.length ? (
        <style>
          {`@import url('https://fonts.googleapis.com/css?family=${missingFonts
            .map((font) => font.replace(/\s/gi, '+'))
            .join('|')}');`}
        </style>
      ) : null}
      <ThemeComponent
        backgroundColor={backgroundColor || '#FFF'}
        activePage={get(page, 'name')}
        logo={logoURL}
        pages={sortedPages.filter(
          (page) => page.navigationMenu === 'ShowAlways' || !page.navigationMenu
        )}
        title={label}
        showAuthLinks={false}
        onNavClick={handleNavClick}
        useLogoOriginalSize={useLogoOriginalSize === 'true'}
      >
        <Plugins
          page={page}
          pageId={get(page, 'id')}
          memberId={memberId}
          plugins={filteredPlugins}
          columns={columns}
          setPage={handleSetPage}
        />
      </ThemeComponent>
    </div>
  );
}

export default connect(mapState)(Site);
