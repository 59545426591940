import React, { useState, useEffect } from 'react';
import debounce from 'lodash/debounce';
import Input from '@salesforce/design-system-react/lib/components/input';

import styles from './AmountScreen.module.scss';

interface Props {
  type: string;
  value: string | number;
  onChange?: (value: string) => void;
}

export default function AmountForm({ type, value, onChange }: Props) {
  const [amount, setAmount] = useState(value);

  useEffect(() => {
    setAmount(value);
  }, [value]);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = event.currentTarget ? event.currentTarget.value : '0';
    setAmount(newValue);
    debouncedChange(newValue);
  };

  const handleBlur = (newValue?: string | number) => {
    const isParamAValue =
      typeof newValue === 'string' || typeof newValue === 'number';

    if (typeof onChange === 'function') {
      onChange(isParamAValue ? String(newValue) : String(amount));
    }
  };
  const debouncedChange = debounce(handleBlur, 300);

  switch (type) {
    case 'fixed':
      return (
        <div className="slds-p-bottom_large slds-text-align_center">
          <div className="slds-text-heading_large">${amount}</div>
        </div>
      );

    case 'variable':
      return (
        <div className="slds-p-bottom_large slds-text-align_center">
          <div className="slds-text-heading_large">
            <Input
              fixedTextLeft="$"
              className={styles.variableAmountInput}
              value={amount}
              type="number"
              onChange={handleChange}
              onBlur={handleBlur}
            />
          </div>
        </div>
      );

    case 'cardonly':
    default:
      return null;
  }
}
