import api, { URL } from 'helpers/api';
import Request from 'axios-request-handler';

import calculateUnread from 'helpers/calculateUnread';
import { scrollToBottom } from '../../components/Chat/helper';
import { Message } from './types';

export default function ChatEffects(dispatch: any) {
  return {
    async getMessages(params: any | undefined, rootState: any) {
      const {
        app: {
          orgId,
          roomId,
          sessionId,
          token,
          user: { crmId },
        },
        site: { defaultRoomMessage, defaultAutoResponse },
      } = rootState;
      const key = `lst-msg-ts-${token}`;
      const lastReadKey = `lst-read-ts-${token}`;

      const { success, messages }: { success: boolean; messages: Message[] } =
        await api.get(`/rooms/${roomId}/messages`);

      if (success) {
        const sortedMessages = messages
          .filter((message: Message) => message.success && !message.isDeleted)
          .sort((a: Message, b: Message) =>
            new Date(a.createdDate) > new Date(b.createdDate) ? 1 : -1
          );

        const shouldInjectAutoResponse =
          sortedMessages.filter(
            (message: Message) => message.body !== defaultRoomMessage
          ).length > 0 && String(defaultAutoResponse).trim() !== '';

        if (shouldInjectAutoResponse) {
          const injectIndex = String(defaultRoomMessage).trim() !== '' ? 2 : 1;
          sortedMessages.splice(injectIndex, 0, {
            isDeleted: false,
            itemId: '',
            memberPhotoURL: '',
            orgId: '',
            roomId: '',
            success: true,
            id: 'chat-auto-response',
            createdDate: sortedMessages[injectIndex].createdDate,
            body: defaultAutoResponse,
            memberId: '',
            crmId: '',
            memberDisplayName: 'Room Chat Bot',
          });
        }

        dispatch.chat.setInitialized(true);
        dispatch.chat.setMessages(sortedMessages);
        scrollToBottom();

        if (sortedMessages.length > 0) {
          const latestId = sortedMessages[sortedMessages.length - 1].id;
          localStorage.setItem(key, String(latestId));
        }

        const lastReadTimestamp = localStorage.getItem(lastReadKey);
        const unreadMessages = calculateUnread(
          sortedMessages,
          Number(lastReadTimestamp),
          crmId
        );
        dispatch.chat.setUnreadNum(unreadMessages);
      }

      // Regardless of the success state that the initial get messages responded with, we'd still want to start poll.
      const options = {
        cancelable: true,
        headers: { Authorization: sessionId },
      };

      const ACTIVE_POLL_TIMEOUT = 3000;
      const cachedLastMessageId = localStorage.getItem(key) || Date.now();

      const prefixPath = `/rooms/${roomId}/messages/since/`;
      const path = `${prefixPath}${cachedLastMessageId}`;
      const urlPath = `${URL}/orgs/${orgId}${path}`;
      const requestInstance = new Request(urlPath, options);

      // Polling
      const runPolling = () => {
        requestInstance.poll(ACTIVE_POLL_TIMEOUT).get(({ data }: any) => {
          if (data.success && data.messages.length > 0) {
            const sortedData = data.messages
              .filter(
                (message: Message) => message.success && !message.isDeleted
              )
              .sort((a: Message, b: Message) =>
                new Date(a.createdDate) > new Date(b.createdDate) ? 1 : -1
              );

            dispatch.chat.addMessages(sortedData);

            const latestId = sortedData[sortedData.length - 1].id;
            localStorage.setItem(key, String(latestId));

            const lastReadTimestamp = localStorage.getItem(lastReadKey);
            const unreadMessages = calculateUnread(
              sortedData,
              Number(lastReadTimestamp),
              crmId
            );
            dispatch.chat.addUnreadNum(unreadMessages);
          }

          const cachedLastMessageId = localStorage.getItem(key) || Date.now();

          const path = `${prefixPath}${cachedLastMessageId}`;
          const urlPath = `${URL}/orgs/${orgId}${path}`;
          requestInstance.setUrl(urlPath);
        });
      };

      runPolling();
    },

    async sendMessage(message: string, rootState: any) {
      const {
        app: {
          roomId,
          memberId,
          user: { crmId },
          memberDisplayName,
        },
        site: { defaultAutoResponse },
        chat: { messages },
      } = rootState;

      const postData: any = {
        body: message,
        memberId: memberId || '',
        memberDisplayName,
        crmId,
      };

      const tempId = Math.random().toString(36);

      dispatch.chat.addMessage({
        success: true,
        ...postData,
        id: tempId,
        createdDate: new Date(),
      });

      function callback({ success, ...res }: any) {
        if (success !== null) {
          dispatch.chat.updateMessage({ success, ...res, memberId, tempId });

          const hasAutoResponse =
            messages.find((msg: any) => msg.body === defaultAutoResponse) !==
            undefined;

          if (defaultAutoResponse && !hasAutoResponse) {
            dispatch.chat.addMessage({
              success: true,
              id: 'chat-auto-response',
              createdDate: new Date(),
              body: defaultAutoResponse,
              memberId: '',
              crmId: '',
              memberDisplayName: 'Room Chat Bot',
            });
          }
        }
      }

      const result = await api.post(`/rooms/${roomId}/messages`, postData);

      callback(result);
    },
  };
}
