import { State, KeyValuePair } from './types';

export function update(state: State, params: KeyValuePair) {
  return {
    ...state,
    [params.key]: params.value,
  };
}

export function modify(state: State, params: any) {
  return {
    ...state,
    ...params,
  };
}

export function deepModify(state: State, params: KeyValuePair) {
  if (params.key in state) {
    return {
      ...state,
      [params.key]: {
        ...(state as any)[params.key],
        ...params.value,
      },
    };
  }
}
