import { schema } from 'normalizr';
import plugin from './plugins';

const page = new schema.Entity(
  'pages',
  {
    plugins: [plugin],
  },
  {
    idAttribute: 'name',
  }
);

const pages = { pages: [page] };
export default pages;
