export default function serialPromise(
  items: any[],
  func: any,
  def: any = undefined
) {
  return items.reduce(
    (promise, item, index) =>
      promise.then((previous: any) => func(item, previous, index)),
    Promise.resolve(def)
  );
}
