import serialPromise from 'helpers/serialPromise';

export async function detectMissingFonts(list: string[]): Promise<string[]> {
  const fontEntries = (document as any).fonts?.entries?.();

  if (fontEntries) {
    const availableFonts = new Set();
    let next = fontEntries.next();
    while (!next.done) {
      const fontFace = Array.isArray(next.value) ? next.value[0] : next.value;
      if (fontFace) {
        availableFonts.add(fontFace.family.replace(/['"]/g, ''));
      }
      next = fontEntries.next();
    }
    return list.filter((font) => !availableFonts.has(font));
  }
  return await serialPromise(
    list,
    async (font: string, stack: string[]) => {
      const isAvailable = !!(await (document as any).fonts?.check(
        `12px ${font}`
      ));
      return isAvailable ? stack : [...stack, font];
    },
    [] as string[]
  );
}
