import * as reducers from './reducers';
import effects from './effects';

const AppModel = {
  state: {
    id: '',
    name: '',
    label: '',
    displayLabel: '',
    logoURL: '',
    showHeader: true,
    showFooter: true,
    pages: [],
    plugins: {},
    backgroundColor: '',
    itemDeleteAccess: 0,
    messagingAccess: 0,
    defaultRoomMessage: '',
    defaultAutoResponse: '',
    sobjectType: '',
    supportedFileTypes: [
      'application/msword',
      'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
      'application/pdf',
      'image/jpeg',
      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
      'application/vnd.ms-excel',
      'text/plain',
      'text/html',
    ],
    options: {} as Record<string, string>,
    esign: {
      enableFreeFormSignatures: true,
      enableAdoptedSignatures: true,
    },
  },
  reducers,
  effects,
};

export default AppModel;
