// @TODO: This is just an experiment using unstated-next, might make sense to move this back to redux in the future.
import { useReducer } from 'react';
import { createContainer } from 'unstated-next';

import { Field } from 'types/payload-types';

export type SFieldFlags = {
  error: boolean;
  hidden: boolean;
};

export type FieldWithFlags = Field & SFieldFlags;

export type ErrorStack = {
  index: number;
  message: string;
};

type Action = {
  type: string;
  key: string;
  value: any;
  overwrite?: boolean;
};

type State = {
  [key: string]: FieldWithFlags[];
};

function reducer(state: State, action: Action) {
  const { type, key, value, overwrite } = action;
  switch (type) {
    case 'push': {
      const stack = Array.isArray(state[key]) ? state[key] : [];

      return {
        ...state,
        [key]: [...stack, value],
      };
    }

    case 'set': {
      const stack = Array.isArray(state[key]) ? state[key] : [];
      let index = stack.findIndex((field: Field) => field.name === value.name);

      if (!overwrite && index !== -1) {
        return state;
      }

      if (index === -1) {
        index = stack.length;
      }

      return {
        ...state,
        [key]: [
          ...stack.slice(0, index),
          value,
          ...stack.slice(index + 1, stack.length),
        ],
      };
    }

    case 'set-errors': {
      const stack = Array.isArray(state[key]) ? state[key] : [];

      return {
        ...state,
        [key]: stack.reduce(
          (fields: FieldWithFlags[], field: FieldWithFlags, index: number) => {
            const errorIndex = value.findIndex(
              (item: ErrorStack) => item.index === index
            );

            if (errorIndex !== -1) {
              return [
                ...fields,
                {
                  ...field,
                  error: value[errorIndex].message,
                },
              ];
            }

            return [...fields, field];
          },
          []
        ),
      };
    }

    case 'hide': {
      const stack = Array.isArray(state[key]) ? state[key] : [];
      return {
        ...state,
        [key]: stack.map((field: FieldWithFlags) => ({
          ...field,
          hidden: true,
        })),
      };
    }

    default:
      return state;
  }
}

function useSFieldState() {
  const [state, dispatch] = useReducer(reducer, {});

  return {
    getState: (key: string, fieldName?: string) => {
      const stack = state[key] || [];
      if (!fieldName) {
        return stack;
      }

      return stack.find((item: FieldWithFlags) => item.name === fieldName);
    },
    onChange: (key: string, value: Field, overwrite: boolean = true) =>
      dispatch({ type: 'set', key, value, overwrite }),
    setErrors: (key: string, value: ErrorStack[]) =>
      dispatch({ type: 'set-errors', key, value }),
    hideForm: (key: string) => dispatch({ type: 'hide', key, value: '' }),
  };
}

const SFieldContext = createContainer(useSFieldState);

export default SFieldContext;
