import React, { useEffect, useRef, useState } from 'react';

import styles from './Script.module.scss';

interface Props {
  plugin: any; // @HACK: No plugin type yet... need to do that when fixing up techdebt.
  value: string;
}

function Script({ value, plugin }: Props) {
  const iframeRef = useRef<HTMLIFrameElement>(null);
  const [height, setHeight] = useState(0);

  useEffect(() => {
    if (!iframeRef.current) return;

    const parser = new DOMParser();
    const dom = parser.parseFromString(value, 'text/html');

    const documentResizeScript = dom.createElement('script');
    documentResizeScript.innerHTML = `
      window.parent.postMessage({
        id: '${plugin.name}',
        height: document.body.clientHeight,
        width: document.body.clientWidth,
      }, '${window.location.origin}');
    `;

    dom.body.appendChild(documentResizeScript);
    dom.body.style.margin = '0';

    iframeRef.current.setAttribute('srcdoc', dom.documentElement.outerHTML);

    const handleIFrameMessage = ({ data }: MessageEvent) => {
      if (data.id !== plugin.name || plugin.height) return;

      setHeight(data.height);
    };

    window.addEventListener('message', handleIFrameMessage);
    return () => {
      window.removeEventListener('message', handleIFrameMessage);
    };
  }, [value, plugin.name, plugin.height]);

  useEffect(() => {
    setHeight(plugin.height);
  }, [plugin.height]);

  return (
    <iframe
      title={plugin.name}
      src="about:blank"
      ref={iframeRef}
      className={styles.iframe}
      style={{
        height,
        width: plugin.width ? plugin.width : '100%',
      }}
    />
  );
}

export default Script;
