import React from 'react';

import styles from './DataGrid.module.scss';

interface Props {
  value: string;
}

function DataGrid({ value }: Props) {
  const __html = value.replace(
    /(<p[^>]*>)<\/p>/gi,
    (_, p1) => `${p1}&#65279;</>`
  );
  return (
    <div className={styles.container} dangerouslySetInnerHTML={{ __html }} />
  );
}

export default DataGrid;
