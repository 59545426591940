import React, { useState, useEffect } from 'react';
import InputIcon from '@salesforce/design-system-react/lib/components/icon/input-icon';

import SalesforcePicklist from './SFieldPicklist';
import FIELD_TYPES from './constants/fieldTypes';
import { Field } from 'types/payload-types';
import { CheckboxValue, InputValue } from 'types/slds-types';
import SFieldState from 'states/SFieldState';

import styles from './SalesforceField.module.scss';

type Props = {
  plugin: any;
  pageId: string;
};

type FieldTypes =
  | 'textinput'
  | 'numberinput'
  | 'dateinput'
  | 'datetimeinput'
  | 'email'
  | 'url'
  | 'phone'
  | 'checkbox'
  | 'picklist';

export default function SalesforceField({ plugin, pageId }: Props) {
  const [value, setValue] = useState('');
  const { onChange, getState } = SFieldState.useContainer();
  const { field = {} } = plugin;
  const { required } = field;
  const state = getState(pageId, field.name) || {};
  const fieldType = (field.type || 'textinput') as FieldTypes;
  const style = {
    width: plugin.width || 300,
    maxWidth: plugin.width || 300,
    height: plugin.height || 'auto',
  };

  useEffect(() => {
    // @HACK: Just a workaround
    onChange(pageId, field, false);

    setValue(field.value || '');
  }, []);

  if (state.hidden) {
    return null;
  }

  function handlePicklistChange(field: Field) {
    onChange(pageId, field);
    setValue(field.value);
  }

  function handleCheckboxChange(
    event: React.ChangeEvent,
    { checked }: CheckboxValue
  ) {
    onChange(pageId, { ...field, value: String(checked) });
    setValue(String(checked));
  }

  function handleGenericChange(
    event: React.ChangeEvent<HTMLTextAreaElement>,
    extra?: InputValue
  ) {
    if (extra && extra.value) {
      onChange(pageId, { ...field, value: extra.value });
      setValue(extra.value);
    } else {
      onChange(pageId, { ...field, value: event.target.value });
      setValue(event.target.value);
    }
  }

  if (fieldType === 'picklist') {
    return (
      <div className={styles.container}>
        <SalesforcePicklist
          plugin={plugin}
          style={style}
          className={styles.w300}
          onChange={handlePicklistChange}
          value={state.value || value}
        />
      </div>
    );
  }

  const FIELD = FIELD_TYPES[fieldType];
  const Component = FIELD.component;
  const { displayLabel } = plugin;

  if (fieldType === 'checkbox') {
    const _value = state.value || value;
    return (
      <div className={styles.container}>
        <Component
          labels={{ label: displayLabel || field.label }}
          assistiveText={{ label: displayLabel }}
          onChange={handleCheckboxChange}
          checked={_value === 'true'}
        />
      </div>
    );
  }

  let extraProps = {};
  if (FIELD.icon) {
    extraProps = {
      iconRight: (
        <InputIcon
          assistiveText={{
            icon: FIELD.icon.name,
          }}
          name={FIELD.icon.name}
          category={FIELD.icon.category}
        />
      ),
    };
  }

  return (
    <div className={styles.container}>
      <Component
        type={FIELD.type}
        label={displayLabel || field.label}
        styleContainer={style}
        className={styles.w300}
        required={required}
        onChange={handleGenericChange}
        errorText={state.error || ''}
        value={state.value || value}
        {...extraProps}
      />
    </div>
  );
}
