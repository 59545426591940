import React, { useState, useRef, useEffect, useCallback } from 'react';
import Button from '@salesforce/design-system-react/lib/components/button';
import Modal from '@salesforce/design-system-react/lib/components/modal';
import Tabs from '@salesforce/design-system-react/lib/components/tabs';
import TabsPanel from '@salesforce/design-system-react/lib/components/tabs/panel';
import Combobox from '@salesforce/design-system-react/lib/components/combobox';
import Checkbox from '@salesforce/design-system-react/lib/components/checkbox';
import SignaturePad from 'components/SignaturePad';
import { toCanvas } from 'html-to-image';

import { ToastyManager } from 'containers/Toasty';
import fonts from '../constants/fonts';

import styles from './Document.module.scss';

const ESIGN_TYPES = {
  FREE_FORM: 'freeform',
  ADOPTED: 'adopted',
};

type Props = {
  terms: string;
  user: any;
  onRequestClose: () => void;
  onRequestSave: (image: string) => void;
  isOpen: boolean;
  isLoading: boolean;
  enableFreeFormSignatures: boolean;
  enableAdoptedSignatures: boolean;
};

const initialState = {
  step: 0,
  selectedFont: fonts[0],
  selectedTab: 0,
  isConverting: false,
  isEmpty: true,
  isTextEmpty: false,
};

export default function ESign({
  terms,
  user,
  onRequestClose,
  onRequestSave,
  isOpen,
  isLoading,
  enableFreeFormSignatures,
  enableAdoptedSignatures,
}: Props) {
  const [step, setStep] = useState(initialState.step);
  const [selectedFont, setSelectedFont] = useState(initialState.selectedFont);
  const [selectedTab, setSelectedTab] = useState(initialState.selectedTab);
  const [isConverting, setIsConverting] = useState(initialState.isConverting);
  const [isEmpty, setIsEmpty] = useState(initialState.isEmpty);
  const [isTextEmpty, setIsTextEmpty] = useState(initialState.isTextEmpty);
  const canvasRef = useRef<any>(null);
  const sigTextAreaRef = useRef<HTMLDivElement>(null);
  const defaultText = [user.firstName, user.lastName].join(' ');

  useEffect(() => {
    // set state to initialState on open
    setStep(initialState.step);
    setSelectedFont(initialState.selectedFont);
    setSelectedTab(initialState.selectedTab);
    setIsConverting(initialState.isConverting);
    setIsEmpty(initialState.isEmpty);
    setIsTextEmpty(initialState.isTextEmpty);
  }, [isOpen]);

  const handleCanvasBegin = useCallback(() => {
    setIsEmpty(false);
  }, []);

  const getESignType = useCallback(() => {
    return selectedTab === 1 || !enableFreeFormSignatures
      ? ESIGN_TYPES.ADOPTED
      : ESIGN_TYPES.FREE_FORM;
  }, [selectedTab, enableFreeFormSignatures]);

  const handleSave = useCallback(async () => {
    const currentESignType = getESignType();

    if (currentESignType === ESIGN_TYPES.FREE_FORM) {
      if (canvasRef && canvasRef.current) {
        const base64Image = (canvasRef.current as unknown as any).toDataURL(
          'image/png'
        );

        if (canvasRef.current.isEmpty()) {
          ToastyManager.emit({
            variant: 'error',
            type: 'toast',
            message: 'Cannot sign the document with a blank signature.',
          });
        } else {
          onRequestSave(base64Image);
        }

        return;
      }
    } else if (currentESignType === ESIGN_TYPES.ADOPTED) {
      if (sigTextAreaRef && sigTextAreaRef.current) {
        setIsConverting(true);
        const canvas = await toCanvas(sigTextAreaRef.current as unknown as any);

        const base64Image = canvas.toDataURL('image/png');

        setIsConverting(false);
        onRequestSave(base64Image);

        return;
      }
    }

    onRequestSave('');
  }, [getESignType, onRequestSave]);

  const handleClearCanvas = useCallback(() => {
    if (canvasRef && canvasRef.current) {
      (canvasRef.current as unknown as any).clear();
      setIsEmpty(true);
    }
  }, []);

  const handleFontChange = useCallback((_: any, data: any) => {
    if (data.selection && data.selection && data.selection[0]) {
      setSelectedFont(data.selection[0]);
    }
  }, []);

  const handleTabChange = useCallback((index: number) => {
    setSelectedTab(index);
  }, []);

  const handleAgreeCheck = useCallback(() => {
    setStep((state) => state + 1);
  }, []);

  const handleAdoptedSignatureChange = useCallback(() => {
    if (!sigTextAreaRef.current) return;

    const textContent = sigTextAreaRef.current.textContent;

    setIsTextEmpty(!textContent?.trim());
  }, []);

  const renderTerms = () => {
    return (
      <div className="slds-p-around_large">
        <p className="slds-m-bottom_medium">
          <Checkbox
            assistiveText={{
              label: 'I agree to use electronic records and signatures',
            }}
            labels={{
              label: 'I agree to use electronic records and signatures',
            }}
            onChange={handleAgreeCheck}
          />
        </p>
        <p>{terms}</p>
      </div>
    );
  };

  const renderESignForm = () => {
    return (
      <Tabs className={styles.esignTabsContainer} onSelect={handleTabChange}>
        {enableFreeFormSignatures ? (
          <TabsPanel label="Draw">
            <div className={styles.sigPadOuterContainer}>
              <div className={styles.sigPadContainer}>
                <div className={styles.sigPadBackground}>
                  <span className={styles.sigPadSignHere}>
                    Please Sign Here
                  </span>
                  <div className={styles.sigPadMessage}>
                    <p className={styles.sigPadUnderlineContainer}>
                      <span>X</span>
                      <span className={styles.sigPadUnderline} />
                    </p>
                  </div>
                </div>
                <SignaturePad
                  penColor="black"
                  minWidth={1}
                  maxWidth={4}
                  canvasClassName={styles.sigPad}
                  onBegin={handleCanvasBegin}
                  ref={canvasRef}
                />
                <Button
                  label="Clear"
                  className={styles.canvasClearButton}
                  onClick={handleClearCanvas}
                  variant="base"
                />
              </div>
            </div>
          </TabsPanel>
        ) : null}
        {enableAdoptedSignatures ? (
          <TabsPanel label="Type">
            <div className={styles.signatureTextContainer}>
              <Combobox
                className="slds-m-bottom_small"
                labels={{ label: 'Font' }}
                selection={[selectedFont]}
                options={fonts}
                variant="readonly"
                events={{
                  onSelect: handleFontChange,
                }}
              />
              <div className={styles.signatureTextAreaBorder}>
                <div
                  ref={sigTextAreaRef}
                  className={styles.signatureTextArea}
                  style={{ fontFamily: selectedFont.id }}
                  onKeyUp={handleAdoptedSignatureChange}
                  contentEditable
                >
                  {defaultText}
                </div>
              </div>
            </div>
          </TabsPanel>
        ) : null}
      </Tabs>
    );
  };

  return (
    <Modal
      dismissOnClickOutside={false}
      onRequestClose={onRequestClose}
      size="small"
      containerClassName={styles.eSignModal}
      contentClassName={styles.eSignModalContent}
      footer={
        step === 1 ? (
          <div className={styles.esignFooter}>
            <Button
              label="Cancel"
              disabled={isLoading || isConverting}
              onClick={onRequestClose}
            />
            <Button
              label="Sign"
              variant="brand"
              disabled={
                isLoading ||
                isConverting ||
                (getESignType() === ESIGN_TYPES.FREE_FORM &&
                  isEmpty &&
                  enableFreeFormSignatures) ||
                (getESignType() === ESIGN_TYPES.ADOPTED &&
                  isTextEmpty &&
                  enableAdoptedSignatures)
              }
              onClick={handleSave}
            />
          </div>
        ) : undefined
      }
      isOpen={isOpen}
    >
      {step === 0 ? renderTerms() : null}
      {step === 1 ? renderESignForm() : null}
    </Modal>
  );
}
