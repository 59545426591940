import { Message } from 'containers/Chat/types';

export default function calculateUnread(
  data: Message[],
  lastReadTimestamp: number,
  crmId: string
) {
  if (lastReadTimestamp) {
    return data.filter(
      (message: Message) =>
        Number(message.id) > Number(lastReadTimestamp) &&
        message.crmId !== crmId
    ).length;
  }

  const ownMessages = data.filter(
    (message: Message) => message.crmId === crmId
  );
  const latestOwnMessage =
    ownMessages.length > 0 ? ownMessages[ownMessages.length - 1].id : 0;
  const unreadMessages = data.filter(
    (message: Message) =>
      message.id > latestOwnMessage && message.crmId !== crmId
  );

  return unreadMessages.length;
}
