import { init, RematchRootState, RematchDispatch } from "@rematch/core";
import * as models from "containers/models";
import createLoadingPlugin, { ExtraModelsFromLoading } from "@rematch/loading";
import createSelectPlugin from "@rematch/select";

type RootModel = typeof models;
type FullModel = ExtraModelsFromLoading<RootModel>;

const loadingOpt = { blacklist: ["chat/sendMessage"] };

export const store = init<RootModel, FullModel>({
  models,
  plugins: [createLoadingPlugin(loadingOpt), createSelectPlugin()],
});

// @HACK: Dunno how to get createLoadingPlugin to work well with typings
interface LoadingTypes {
  global: boolean;
  models: any;
  effects: any;
}
interface Loading {
  loading: LoadingTypes;
}

export type Store = typeof store;
export type Dispatch = RematchDispatch<RootModel>;
export type RootState = RematchRootState<RootModel> & Loading;
