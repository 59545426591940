/** @jsxImportSource @emotion/react */
import { useState, useCallback } from 'react';
import ReactDOM from 'react-dom';
import Icon from '@salesforce/design-system-react/lib/components/icon';
import Button from '@salesforce/design-system-react/lib/components/button';
import Spinner from '@salesforce/design-system-react/lib/components/spinner';
import cx from 'classnames';
import get from 'lodash/get';
import omit from 'lodash/omit';
import capitalize from 'lodash/capitalize';
import { css } from '@emotion/react';

import ToastyManager from 'containers/Toasty/ToastyManager';
import ESign from './ESign';
import mapIcon from 'helpers/fileIconMap';
import api from 'helpers/api';

import styles from './Document.module.scss';

export interface ActionProps {
  type: string;
  id?: string;
  url?: string;
  data?: any;
}

interface Props {
  orgId: string;
  roomId: string;
  actions: any[];
  extraData: any;
  plugin?: any;
  user: any;
  canDelete: boolean;
  onActionClick: (action: ActionProps) => any;
  setPage: (page: string) => void;
  enableFreeFormSignatures: boolean;
  enableAdoptedSignatures: boolean;
}

let timeout: any;
function Document({
  orgId,
  roomId,
  actions,
  extraData,
  plugin,
  user,
  canDelete,
  setPage,
  onActionClick,
  enableFreeFormSignatures,
  enableAdoptedSignatures,
}: Props) {
  const [isLoading, setIsLoading] = useState(false);
  const [showESign, setShowESign] = useState(false);
  const [hasViewedDocument, setHasViewedDocument] = useState(false);
  const [ident, setIdent] = useState(0);
  const [esignNextPage, setEsignNextPage] = useState('');
  const [isPdfViewerOpen, setIsPdfViewerOpen] = useState(false);
  const [pdfUrl, setPdfUrl] = useState('');
  const [isPdfDownloadLoading, setIsPdfDownloadLoading] = useState(false);

  const handleESignDone = useCallback(
    async (image: string) => {
      setShowESign(false);

      try {
        setIsLoading(true);

        const { success } = await onActionClick({
          type: 'esign',
          id: extraData.id,
          data: {
            body: image,
          },
        });

        if (success) {
          ToastyManager.emit({
            variant: 'success',
            type: 'toast',
            message: 'eSignature Completed. Please check your email inbox.',
          });

          if (esignNextPage) {
            setPage(esignNextPage);
          }
        }

        setIsLoading(false);

        setEsignNextPage('');
      } catch (error) {
        console.error(error);
      }
    },
    [esignNextPage]
  );

  if (!get(extraData, ['Id']) && !get(extraData, ['id'])) {
    return null;
  }

  const fileExtension = extraData.fileExtension || extraData.FileExtension;
  const label = extraData.label || extraData.title || extraData.Title;
  const description = extraData.description;

  async function handleDocumentClick() {
    setHasViewedDocument(true);
    setIsLoading(true);

    try {
      const response = await api.post(extraData.contentURL, {
        orgId: orgId,
        roomId: roomId,
        itemId: extraData.id || extraData.Id,
        downloadItem: true,
      });

      // We're going to do a superb hack for Safari
      if (
        navigator.userAgent.indexOf('Safari') !== -1 &&
        navigator.userAgent.indexOf('Chrome') === -1
      ) {
        setIsPdfViewerOpen(true);
        setPdfUrl(response.item.signedURL);
      } else {
        window.open(response.item.signedURL, 'idlog-download');
      }
    } catch (error) {
      console.error(error);
    }

    if (ident) {
      setIdent(0);

      ToastyManager.emit({
        type: 'delete-toast',
        ident,
      });
    }

    setIsLoading(false);
  }

  async function handleDownloadDocument() {
    try {
      clearTimeout(timeout);
      setIsPdfDownloadLoading(true);
      const response = await api.post(extraData.contentURL, {
        orgId: orgId,
        roomId: roomId,
        itemId: extraData.id || extraData.Id,
        downloadItem: true,
        disposition: 'attachment',
      });
      // hack for safari
      const iframe = document.createElement('iframe');
      iframe.src = response.item.signedURL;
      document.body.appendChild(iframe);

      iframe.addEventListener('load', () => {
        document.body.removeChild(iframe);
        iframe.remove();
        setIsPdfDownloadLoading(false);
      });

      timeout = setTimeout(() => {
        document.body.removeChild(iframe);
        iframe.remove();
        setIsPdfDownloadLoading(false);
      }, 5000);
      // document.body.removeChild(a);
    } catch (error) {
      console.error(error);
    }
  }

  async function handleDelete() {
    try {
      setIsLoading(true);
      await onActionClick({
        type: 'delete',
        id: extraData.Id || extraData.id,
        url: extraData.contentURL,
      });
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
    }
  }

  function handleActionButtonClick(action: any) {
    return async (event: any) => {
      event.preventDefault();
      /* Commented out Feb 14, 2023. Potentially moving to feature flag / setting to re-enable.
      Default = off
      if (action.eventType === 'DOCUMENT_ESIGNED' && !hasViewedDocument) {
        const _ident = Date.now();

        ToastyManager.emit({
          variant: 'error',
          type: 'toast',
          message:
            'Please review the document prior to eSignature. Click on the document or action button to open.',
          ident: _ident,
        });
        setIdent(_ident);
        return;
      }
      */

      if (action.eventType === 'DOCUMENT_VIEW') {
        await handleDocumentClick();
      } else if (action.eventType === 'DOCUMENT_ESIGNED') {
        setShowESign(true);
        setEsignNextPage(action.nextPage);
        return;
      } else if (action.postOnClick) {
        setIsLoading(true);
        await onActionClick({
          type: 'generic-action',
          id: extraData.id,
          data: {
            ...omit(action, [
              'id',
              'clientTimestamp',
              'isDeleted',
              'label',
              'name',
              'order',
              'success',
            ]),
            pluginId: plugin.id,
          },
        });
        setIsLoading(false);
      }

      if (action.nextPage) {
        setPage(action.nextPage);
      }
    };
  }

  function handleESignClose() {
    if (!isLoading) {
      setShowESign(false);
      setEsignNextPage('');
    }
  }

  return (
    <div className={styles.container}>
      <div
        className={cx('slds-grid slds-wrap slds-is-relative', styles.wrapper)}
      >
        {isLoading && <Spinner size="small" />}
        <div className={cx(styles.column, styles.documentContainer)}>
          <div className="slds-grid slds-grid_vertical-align-center">
            {canDelete && (
              <Button
                assistiveText={{ icon: 'Delete Document' }}
                iconCategory="action"
                iconName="remove"
                iconSize="small"
                iconVariant="bare"
                inverse
                className={cx(
                  styles.deleteButton,
                  'slds-m-right_small',
                  'slds-m-top_xx-small'
                )}
                onClick={handleDelete}
                variant="icon"
                disabled={isLoading}
              />
            )}

            <div>
              <Icon
                assistiveText={{
                  label: mapIcon(fileExtension),
                }}
                containerClassName="slds-text-link_reset"
                category="doctype"
                colorVariant="default"
                className="slds-p-right_x-small slds-button"
                name={mapIcon(fileExtension)}
                size="medium"
                onClick={handleDocumentClick}
              />
            </div>
            <label
              className={cx('slds-text-link_reset', styles.itemLabel)}
              title={description}
              onClick={handleDocumentClick}
            >
              {label}
            </label>
          </div>
        </div>
        <div
          className={cx(
            styles.column,
            styles.actionsContainer,
            styles[`actionAlign${capitalize(plugin.alignment)}`]
          )}
        >
          {actions
            .filter((action: any) => action.isEnabled)
            .sort((left, right) => (left.order < right.order ? -1 : 1))
            .map((action: any) => {
              const buttonBackground = isLoading
                ? '#c9c7c5'
                : action.displayColor;
              const textColor = isLoading ? '#dddbda' : '#0070d2';
              return action.displayType === 'TEXT' ? (
                <div className={styles.actionText}>{action.displayLabel}</div>
              ) : (
                <Button
                  key={action.name}
                  label={action.displayLabel}
                  variant={action.displayType === 'BUTTON' ? 'neutral' : 'link'}
                  className={styles.actionItem}
                  css={css`
                    height: ${Number(action.displayHeight || 32) / 16}rem;

                    @media (hover: none) and (pointer: coarse) {
                      height: ${Number(action.displayHeight || 32) / 13}rem;
                      line-height: ${Number(action.displayHeight || 32) /
                      13}rem;
                    }
                  `}
                  style={{
                    backgroundColor:
                      action.displayType === 'BUTTON'
                        ? buttonBackground
                        : 'transparent',
                    color:
                      action.displayType === 'BUTTON'
                        ? action.displayFontColor || '#ffffff'
                        : textColor,
                    minWidth: action.displayWidth ? 0 : 100,
                    width: action.displayWidth
                      ? Number(action.displayWidth)
                      : 'auto',
                    marginLeft: action.marginLeft
                      ? Number(action.marginLeft)
                      : 0,
                    marginRight: action.marginRight
                      ? Number(action.marginRight)
                      : 10,
                  }}
                  onClick={handleActionButtonClick(action)}
                  disabled={isLoading}
                />
              );
            })}
        </div>
      </div>
      <ESign
        terms={plugin.terms}
        user={user}
        onRequestClose={handleESignClose}
        onRequestSave={handleESignDone}
        isOpen={showESign}
        isLoading={isLoading}
        enableFreeFormSignatures={enableFreeFormSignatures}
        enableAdoptedSignatures={enableAdoptedSignatures}
      />
      {isPdfViewerOpen
        ? ReactDOM.createPortal(
            <div className={styles.pdfContainer}>
              <div className={styles.pdfHeaderContainer}>
                <div>
                  <button
                    className={styles.pdfCloseButton}
                    onClick={() => {
                      setIsPdfViewerOpen(false);
                      setPdfUrl('');
                      setIsPdfDownloadLoading(false);
                    }}
                  >
                    <Icon
                      assistiveText={{
                        label: 'Close Document',
                      }}
                      containerClassName="slds-text-link_reset"
                      category="action"
                      colorVariant="default"
                      className="slds-button"
                      name="close"
                      size="small"
                    />
                  </button>
                </div>
                <div className={styles.pdfDownloadContainer}>
                  {isPdfDownloadLoading ? (
                    <Spinner
                      size="small"
                      variant="base"
                      assistiveText={{ label: 'Downloading...' }}
                    />
                  ) : null}
                  <Button
                    label="Download"
                    iconCategory="utility"
                    iconName="download"
                    iconPosition="left"
                    onClick={handleDownloadDocument}
                    variant="base"
                  />
                </div>
              </div>
              {pdfUrl ? (
                <iframe
                  src={pdfUrl}
                  title="PDF Preview"
                  className={styles.pdfPreviewIframe}
                />
              ) : (
                <div>Cannot load PDF from {pdfUrl}</div>
              )}
            </div>,
            document.body
          )
        : null}
    </div>
  );
}

export default Document;
