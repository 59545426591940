import React from 'react';

import AmountScreen from '../screens/AmountScreen';
import BillingScreen from '../screens/BillingScreen';
import CardScreen from '../screens/CardScreen';

import { KEYS } from '../constants/paymentSteps';
import { Payload, PaymentDetailsProps } from '../types';
import { Plugin, PaymentMethod } from 'containers/Site/types';

interface Props {
  stepId: string;
  plugin: Plugin;
  state: Payload & PaymentDetailsProps;
  stripeKey: string;
  onChange: (payload: Payload & PaymentDetailsProps) => void;
}

export default function PaymentBody({
  stepId,
  plugin,
  state,
  stripeKey,
  onChange,
}: Props) {
  const handleAmountChange = (value: string) => {
    onChange({
      totalAmount: value,
    });
  };

  const handlePersonalBillingChange = (payload: any) => {
    const contacts = state.contacts || [];

    onChange({
      firstName: payload.firstName,
      lastName: payload.lastName,
      email: payload.email,
      billingStreet: payload.street,
      billingCity: payload.city,
      billingState: payload.state,
      billingPostalCode: payload.postalCode,
      billingCountry: payload.country,
      contacts: [payload, ...contacts.slice(1, contacts.length)],
    });
  };

  const handleContactBillingChange = (payload: any) => {
    const contacts = state.contacts || [];

    onChange({
      contacts: [contacts[0] || null, payload],
    });
  };

  const handlePaymentDetailsChange = (payload: any) => {
    onChange(payload);
  };

  switch (stepId) {
    case KEYS.AMOUNT: {
      const { paymentMethod = {} as PaymentMethod } = plugin;
      const { totalAmount } = state;

      return (
        <AmountScreen
          type={paymentMethod ? paymentMethod.type : 'cardonly'}
          value={
            totalAmount !== undefined ? totalAmount : paymentMethod.value || '0'
          }
          onChange={handleAmountChange}
        />
      );
    }

    case KEYS.PERSONAL_DETAILS: {
      const { contacts = [] } = state;
      const details = contacts[0];

      return (
        <BillingScreen
          details={details}
          onChange={handlePersonalBillingChange}
        />
      );
    }

    case KEYS.BILLING_DETAILS: {
      const { contacts = [] } = state;
      const defaultDetails = contacts[0];
      const details = contacts[1] || null;

      return (
        <BillingScreen
          defaultDetails={defaultDetails}
          details={details}
          onChange={handleContactBillingChange}
          isBillingContact
        />
      );
    }

    case KEYS.PAYMENT_DETAILS:
      return (
        <CardScreen
          state={state}
          plugin={plugin}
          stripeKey={stripeKey}
          onChange={handlePaymentDetailsChange}
        />
      );

    default:
      return null;
  }
}
