import * as reducers from './reducers';
import effects from './effects';

const AppModel = {
  state: {
    orgId: '',
    roomId: '',
    sessionId: '',
    token: '',
    memberId: '',
    isInitialized: false,
    role: 1,
    user: {
      firstName: '',
      lastName: '',
      crmId: '',
    },
    showResendToken: false,
    showTwoFactor: false,
    twoFactorMessage: 'A text message with 5-digit code was sent to your mobile phone number ending in 1234',
    room: {} as any,
  },
  reducers,
  effects,
};

export default AppModel;
