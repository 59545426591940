import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import Button from '@salesforce/design-system-react/lib/components/button';
import Spinner from '@salesforce/design-system-react/lib/components/spinner';

import api from 'helpers/api';

import styles from './style.module.css';

const STEPS = {
  DEFAULT: 0,
  SUCCESS: 1,
  CANCEL: 2,
};

function DefaultForm({ onRequestCancel, onRequestResend }: any) {
  return (
    <>
      <p className="slds-m-bottom_x-small">
        The token you tried to access is either expired or invalid.
      </p>
      <p>Are you sure you want to resend your token to your email?</p>
      <div className="slds-grid slds-m-top_medium">
        <Button
          label="Cancel"
          variant="outline-brand"
          className="slds-col"
          onClick={onRequestCancel}
        />
        <Button
          label="Resend"
          variant="brand"
          className="slds-col"
          onClick={onRequestResend}
        />
      </div>
    </>
  );
}

function SuccessForm({ message }: any) {
  return <p className="slds-m-bottom_x-small">{message}</p>;
}

function CancelForm({ onClick }: any) {
  return (
    <>
      <p>You do not have the required permissions to access this page</p>
      <div className="slds-grid slds-m-top_medium">
        <Button
          label="Refresh Access Token"
          variant="brand"
          className="slds-col"
          onClick={onClick}
        />
      </div>
    </>
  );
}

export default function () {
  const [isLoading, setIsLoading] = useState(false);
  const [step, setStep] = useState(0);
  const [message, setMessage] = useState('');
  const token = useSelector((state: any) => state.app.token);

  async function handleResend() {
    try {
      setIsLoading(true);
      const response = await api.post('/auth/tokens/redeem', {
        resend: true,
        origin: 'web',
        token,
      });

      setMessage(response.message);
      setStep(STEPS.SUCCESS);
    } catch (error) {
      // Do something
    }
    setIsLoading(false);
  }

  function handleCancel() {
    setStep(STEPS.CANCEL);
  }

  function handleRefresh() {
    setStep(STEPS.DEFAULT);
  }

  const renderStep = () => {
    switch (step) {
      case STEPS.SUCCESS:
        return <SuccessForm message={message} />;

      case STEPS.CANCEL:
        return <CancelForm onClick={handleRefresh} />;

      case STEPS.DEFAULT:
      default:
        return (
          <DefaultForm
            onRequestResend={handleResend}
            onRequestCancel={handleCancel}
          />
        );
    }
  };

  return (
    <div className={styles.floatingWrapper}>
      <div className="slds-text-heading_large slds-text-align_center slds-m-bottom_small">
        {step !== STEPS.CANCEL && 'Resend Token'}
      </div>
      <div className={styles.floatingContainer}>
        {isLoading && <Spinner size="x-small" />}
        {renderStep()}
      </div>
    </div>
  );
}
