import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

const propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
};

function Chat({ children, className }) {
  return (
    <section role="log" className={cx('slds-chat', className)}>
      <ul className="slds-chat-list">{children}</ul>
    </section>
  );
}

Chat.propTypes = propTypes;

export default Chat;
