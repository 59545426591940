export interface SetCookieArgs {
  [key: string]: string;
}

export interface ParseCookiesType {
  [key: string]: string;
}

export function parseCookies(): ParseCookiesType {
  const cookieSegments = document.cookie.split(';');

  if (cookieSegments.length > 0) {
    return cookieSegments.reduce((res: ParseCookiesType, item: string) => {
      const [k, v] = item.split('=');

      return { ...res, [String(k).trim()]: String(v).trim() };
    }, {});
  }

  return {};
}

export function getCookie(key: string) {
  const cookieSegments = document.cookie.split(';');

  return cookieSegments.reduce((res: string, item: string) => {
    const [k, v] = item.split('=');

    if (String(k).trim() === key) {
      return v;
    }

    return res;
  }, '');
}

export function setCookies(items: SetCookieArgs) {
  const entries = Object.entries(items);

  if (entries.length > 0) {
    document.cookie = entries.reduce(
      (cookieString: string, entry: string[]) =>
        cookieString ? `${cookieString}; ${entry.join('=')}` : entry.join('='),
      ''
    );
  }
}

export function deleteCookie(key: string) {
  document.cookie = `${key}=; expires=Thu, 01 Jan 1970 00:00:00 GMT`;
}

export function cleanCookies(count: number) {
  const entries = document.cookie.split(';');
  const tokens = entries.filter(
    (entry: string) => entry.trim().indexOf('t:') === 0
  );

  if (tokens.length > count) {
    tokens.slice(0, tokens.length - count).forEach((token: string) => {
      const [key] = token.split('=');
      deleteCookie(key);
    });
  }
}
