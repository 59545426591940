import React, { useState, useEffect, useRef } from 'react';
import { IUser } from './types';

interface IProps {
  plugin: any;
  roomId: string;
  memberId: string;
  orgId: string;
  user: IUser;
}

const HTML_PROPS_ASSIGNER = '=';
const HTML_PROPS_DELIMITER = '||';

function parseHTMLProps(htmlProps: string) {
  if (/^\{/.test(htmlProps)) {
    try {
      return JSON.parse(htmlProps);
    } catch (error) {
      // @TODO: Log to sentry?
    }
    return {};
  }

  return htmlProps
    .split(HTML_PROPS_DELIMITER)
    .filter((val) => val.trim() !== '')
    .reduce((stack, item) => {
      const [key, val] = item.split(HTML_PROPS_ASSIGNER);
      return { ...stack, [key]: val };
    }, {});
}

export default function Configurator({
  plugin,
  roomId,
  orgId,
  memberId,
  user,
}: IProps) {
  const [loaded, setLoaded] = useState(false);
  const containerRef = useRef(null);
  const htmlProps = plugin.options?.htmlProps || '';
  const configId = plugin.options?.configurationTemplateId;

  useEffect(() => {
    if (!plugin.contentURL || !configId) return;

    const baseUrl = plugin.contentURL;
    const abortController = new AbortController();

    fetch(`${baseUrl}/manifest.json`, { signal: abortController.signal })
      .then((res) => res.json())
      .then((res) => {
        const { tag, assets } = res;
        const { modulePreloads, modules, scripts, stylesheets } = assets.reduce(
          (obj: any, asset: any) => {
            const url = !/^(http|https):/i.test(asset.url)
              ? `${baseUrl}/build/${asset.url}`
              : asset.url;

            switch (asset.type) {
              case 'modulepreload':
                if (
                  document.head.querySelector(
                    `[rel="modulepreload"][href="${url}"]`
                  ) === null
                ) {
                  return {
                    ...obj,
                    modulePreloads: [...obj.modulePreloads, asset],
                  };
                }
                break;

              case 'module':
                if (
                  document.head.querySelector(
                    `[type="module"][src="${url}"]`
                  ) === null
                ) {
                  return {
                    ...obj,
                    modules: [...obj.modules, asset],
                  };
                }
                break;

              case 'script':
                if (document.head.querySelector(`[src="${url}"]`) === null) {
                  return {
                    ...obj,
                    scripts: [...obj.scripts, asset],
                  };
                }
                break;

              case 'stylesheet':
                if (
                  document.head.querySelector(
                    `[rel="stylesheet"][href="${url}"]`
                  ) === null
                ) {
                  return {
                    ...obj,
                    stylesheets: [...obj.stylesheets, asset],
                  };
                }
                break;

              default:
                return obj;
            }

            return obj;
          },
          { modulePreloads: [], modules: [], scripts: [], stylesheets: [] }
        );

        modulePreloads.forEach((module: any) => {
          const link = document.createElement('link');
          link.setAttribute('rel', 'modulepreload');
          link.setAttribute('href', `${baseUrl}/build/${module.url}`);

          document.head.appendChild(link);
        });

        modules.forEach((module: any) => {
          const extraAttributes = module.attributes
            ? Object.keys(module.attributes)
            : [];
          const script = document.createElement('script');
          script.src = !/^(http|https):/i.test(module.url)
            ? `${baseUrl}/build/${module.url}`
            : module.url;
          script.type = 'module';

          extraAttributes.forEach((key) => {
            script.setAttribute(key, module.attributes[key]);
          });

          document.head.appendChild(script);
        });

        scripts.forEach((module: any) => {
          const script = document.createElement('script');
          script.src = `${baseUrl}/build/${module.url}`;
          script.type = 'module';
          script.setAttribute('data-resources-url', '/build/');
          script.setAttribute('data-stencil-namespace', 'app');

          document.head.appendChild(script);
        });

        stylesheets.forEach((module: any) => {
          const link = document.createElement('link');
          link.setAttribute('rel', 'stylesheet');
          link.setAttribute('href', `${baseUrl}/build/${module.url}`);

          document.head.appendChild(link);
        });

        setLoaded(true);

        if (containerRef.current) {
          const elem = document.createElement(tag);
          const props = parseHTMLProps(htmlProps);

          elem.setAttribute('base-url', baseUrl);
          elem.setAttribute(
            'user',
            JSON.stringify({
              firstName: user.firstName,
              lastName: user.lastName,
              email: user.email,
            })
          );
          elem.setAttribute('org-id', orgId);
          elem.setAttribute('config-id', configId);
          elem.setAttribute('room-id', roomId);
          elem.setAttribute('member-id', memberId);

          Object.keys(props).forEach((prop) => {
            elem.setAttribute(prop, props[prop]);
          });

          (containerRef.current as any).appendChild(elem);
        }
      })
      .catch((error) => {
        console.error(error);
      });

    return () => {
      abortController.abort();
    };
  }, [
    plugin.contentURL,
    htmlProps,
    user.firstName,
    user.lastName,
    user.email,
    orgId,
    configId,
    roomId,
    memberId,
  ]);

  return (
    <div
      ref={containerRef}
      style={{
        overflow: plugin.height ? 'auto' : 'visible',
        height: plugin.height ? Number(plugin.height) : 'auto',
        width: plugin.width ? Number(plugin.width) : 'auto',
      }}
    >
      {loaded ? null : <span>Loading configurator...</span>}
    </div>
  );
}
