import React from 'react';
import cx from 'classnames';
import Button from '@salesforce/design-system-react/lib/components/button';
import Icon from '@salesforce/design-system-react/lib/components/icon';
import { useStripe, useElements } from '@stripe/react-stripe-js';

import { Step } from '../types';

import styles from './PaymentHeader.module.scss';

interface Props {
  step: number;
  steps: Step[];
  finalButtonLabel?: string;
  onRequestStepChange: (step: number) => void;
  onRequestPay: (stripe: any, elements: any) => void;
}

export default function PaymentHeader({
  step,
  steps,
  finalButtonLabel,
  onRequestStepChange,
  onRequestPay,
}: Props) {
  const stripe = useStripe();
  const elements = useElements();
  const handleNext = () => {
    const next = step + 1;

    if (next < steps.length) {
      onRequestStepChange(next >= steps.length ? steps.length - 1 : next);
    } else {
      onRequestPay(stripe, elements);
    }
  };

  const handlePrevious = () => {
    const prev = step - 1;
    onRequestStepChange(prev <= 0 ? 0 : prev);
  };

  const handleStepClick = (index: number) => () => {
    onRequestStepChange(index);
  };

  return (
    <div className={styles.stepsHeader}>
      <Button
        assistiveText={{ icon: 'Icon Bare Small' }}
        iconCategory="utility"
        iconName="chevronleft"
        iconSize="x-small"
        iconVariant="bare"
        variant="icon"
        disabled={step === 0}
        onClick={handlePrevious}
      />
      <div className={cx('slds-text-heading_small', styles.stepsTitle)}>
        {steps[step].label}
      </div>
      <div className={styles.stepsIndicator}>
        {steps.map((item, index) => {
          return (
            <div
              key={`step-bullet-${index}`}
              className={styles.stepsBulletContainer}
              onClick={handleStepClick(index)}
            >
              <Icon
                key={`steps-header-${item.id}-${index}`}
                assistiveText={{ label: `Step ${index}` }}
                category="utility"
                name={index === step ? 'choice' : 'routing_offline'}
                size="x-small"
              />
            </div>
          );
        })}
      </div>
      {step === steps.length - 1 ? (
        <div className={styles.blankSpace} />
      ) : (
        <Button
          assistiveText={{ icon: 'Icon Bare Small' }}
          iconCategory="utility"
          iconName="chevronright"
          iconSize="x-small"
          iconVariant="bare"
          variant="icon"
          onClick={handleNext}
        />
      )}
    </div>
  );
}
