import Icon from '@salesforce/design-system-react/lib/components/icon';
import Input from '@salesforce/design-system-react/lib/components/input';
import Textarea from '@salesforce/design-system-react/lib/components/textarea';
import Checkbox from '@salesforce/design-system-react/lib/components/checkbox';
import Combobox from '@salesforce/design-system-react/lib/components/combobox';

type Icon = {
  name: string;
  category: string;
};

type Field = {
  component: any;
  type: string;
  icon?: Icon;
};

type FieldTypes = {
  [key: string]: Field;
};

const FIELD_TYPES: FieldTypes = {
  textinput: {
    component: Input,
    type: 'text',
  },
  numberinput: {
    component: Input,
    type: 'number',
  },
  dateinput: {
    component: Input,
    type: 'date',
    icon: {
      name: 'date_input',
      category: 'utility',
    },
  },
  datetimeinput: {
    component: Input,
    type: 'datetime-local',
    icon: {
      name: 'date_input',
      category: 'utility',
    },
  },
  email: {
    component: Input,
    type: 'email',
  },
  url: {
    component: Input,
    type: 'url',
  },
  phone: {
    component: Input,
    type: 'tel',
  },
  checkbox: {
    component: Checkbox,
    type: '',
  },
  picklist: {
    component: Combobox,
    type: '',
  },
  textarea: {
    component: Textarea,
    type: '',
  },
};

export default FIELD_TYPES;
