import React, { useRef, useLayoutEffect, useMemo, ForwardedRef } from 'react';
import SignaturePad, { Options, SignatureEvent } from 'signature_pad';
import omit from 'lodash/omit';
import isEqual from 'lodash/isEqual';

type EventCallbackType = (event: SignatureEvent | Event) => void;

interface Props extends Options {
  canvasClassName?: string;
  canvasWidth?: number;
  canvasHeight?: number;
  onBegin?: EventCallbackType;
  onEnd?: EventCallbackType;
  onBeforeUpdate?: EventCallbackType;
  onAfterUpdate?: EventCallbackType;
}

const ReactSignaturePad = React.forwardRef<SignaturePad, Props>(
  (props, ref) => {
    const {
      onBegin,
      onEnd,
      onBeforeUpdate,
      onAfterUpdate,
      canvasHeight,
      canvasWidth,
      canvasClassName,
    } = props;
    const canvasRef = useRef<HTMLCanvasElement>(null);
    const signaturePadRef = useRef<SignaturePad | null>(null);
    // rerender prevention hack:
    const cachedSigPadOptionProps = useRef<Options | null>(null);
    const signaturePadOptionProps = useMemo(() => {
      const omittedProps = omit(props, [
        'onBegin',
        'onEnd',
        'onBeforeUpdate',
        'onAfterUpdate',
        'canvasHeight',
        'canvasWidth',
        'canvasClassName',
      ]);

      if (!isEqual(cachedSigPadOptionProps.current, omittedProps)) {
        cachedSigPadOptionProps.current = omittedProps;
      }

      return cachedSigPadOptionProps.current;
    }, [props]);
    const hasSignaturePadOptions = useMemo(
      () => Object.keys(signaturePadOptionProps || {}).length > 0,
      [signaturePadOptionProps]
    );

    useLayoutEffect(() => {
      if (!canvasRef.current) {
        throw new Error(
          'Signature Canvas failed to mount before initialization'
        );
      }

      signaturePadRef.current = new SignaturePad(
        canvasRef.current,
        hasSignaturePadOptions && signaturePadOptionProps !== null
          ? signaturePadOptionProps
          : undefined
      );

      if (typeof ref === 'function') {
        ref(signaturePadRef.current);
      } else if (ref) {
        ref.current = signaturePadRef.current;
      }

      resizeCanvas();
      attachSignaturePadEventHandlers();
      attachWindowEventHandlers();

      return () => {
        clearSignaturePadEventHandlers();
        clearWindowEventHandlers();
      };
    }, [
      ref,
      hasSignaturePadOptions,
      signaturePadOptionProps,
      onBegin,
      onEnd,
      onBeforeUpdate,
      onAfterUpdate,
    ]);

    function resizeCanvas() {
      if (
        typeof canvasWidth !== 'undefined' &&
        typeof canvasHeight !== 'undefined'
      ) {
        return;
      }

      const canvas = canvasRef.current!;

      const ratio = Math.max(window.devicePixelRatio ?? 1, 1);

      if (typeof canvasWidth === 'undefined') {
        canvas.width = canvas.offsetWidth * ratio;
      }
      if (typeof canvasHeight === 'undefined') {
        canvas.height = canvas.offsetHeight * ratio;
      }

      canvas.getContext('2d')!.scale(ratio, ratio);

      signaturePadRef.current?.clear();
    }

    function attachSignaturePadEventHandlers() {
      if (typeof onBegin === 'function') {
        signaturePadRef.current?.addEventListener('beginStroke', onBegin);
      }

      if (typeof onEnd === 'function') {
        signaturePadRef.current?.addEventListener('endStroke', onEnd);
      }

      if (typeof onBeforeUpdate === 'function') {
        signaturePadRef.current?.addEventListener(
          'beforeUpdateStroke',
          onBeforeUpdate
        );
      }

      if (typeof onAfterUpdate === 'function') {
        signaturePadRef.current?.addEventListener(
          'afterUpdateStroke',
          onAfterUpdate
        );
      }
    }

    function clearSignaturePadEventHandlers() {
      if (typeof onBegin === 'function') {
        signaturePadRef.current?.removeEventListener('beginStroke', onBegin);
      }

      if (typeof onEnd === 'function') {
        signaturePadRef.current?.removeEventListener('endStroke', onEnd);
      }

      if (typeof onBeforeUpdate === 'function') {
        signaturePadRef.current?.removeEventListener(
          'beforeUpdateStroke',
          onBeforeUpdate
        );
      }

      if (typeof onAfterUpdate === 'function') {
        signaturePadRef.current?.removeEventListener(
          'afterUpdateStroke',
          onAfterUpdate
        );
      }
    }

    function attachWindowEventHandlers() {
      window.addEventListener('resize', resizeCanvas);
      signaturePadRef.current?.on();
    }

    function clearWindowEventHandlers() {
      window.removeEventListener('resize', resizeCanvas);
      signaturePadRef.current?.off();
    }

    return <canvas ref={canvasRef} className={canvasClassName} />;
  }
);

export default ReactSignaturePad;
