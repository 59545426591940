const TITLE = 'iDialogue Room';
let timeout = 0;

export default function unreadTitlebar(unread: number) {
  if (unread === 0) {
    clearTimeout(timeout);
    document.title = TITLE;
    return;
  }

  function toggleTitle() {
    clearTimeout(timeout);
    if (document.title === TITLE) {
      document.title = `(${unread}) New Message!`;
    } else {
      document.title = TITLE;
    }

    timeout = window.setTimeout(() => {
      toggleTitle();
    }, 2000);
  }

  toggleTitle();
}
