import React, { useState, useEffect } from 'react';
import uniqid from 'uniqid';
import RadioGroup from '@salesforce/design-system-react/lib/components/radio-group';
import Radio from '@salesforce/design-system-react/lib/components/radio-group/radio';

import FIELD_TYPES from './constants/fieldTypes';
import { Field } from 'types/payload-types';
import { PicklistSelectValue, PicklistOption } from 'types/slds-types';

type KVP = {
  [key: string]: number | string;
};

type Props = {
  value: string;
  plugin: any;
  style: KVP;
  className: string;
  onChange: (field: Field) => void;
};

export default function SalesforcePicklist({
  plugin,
  style,
  className,
  onChange,
  value,
}: Props) {
  const [selected, setSelected] = useState(value); // @HACK?
  const { field = {} as Field, options = {}, displayLabel } = plugin;
  const { entries = [], required } = field;

  useEffect(() => {
    setSelected(value);
  }, [value]);

  if (options.renderAsRadio !== 'false') {
    return (
      <RadioGroup
        labels={{
          label: displayLabel,
        }}
        required={required}
        onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
          setSelected(event.target.value);
          onChange({
            ...field,
            value: event.target.value,
          });
        }}
      >
        {entries.map((entry: string) => (
          <Radio
            labels={{ label: entry }}
            value={entry}
            key={uniqid('sf-field-')}
            checked={entry === selected}
          />
        ))}
      </RadioGroup>
    );
  }

  const Component = FIELD_TYPES.picklist.component;
  const items = entries.map((entry: string) => ({ id: entry, label: entry }));
  const selectedMapped = items.find(
    (item: PicklistOption) => item.id === selected
  );

  return (
    <Component
      variant="readonly"
      options={items}
      labels={{
        label: displayLabel,
        placeholderReadOnly: options.placeholder || 'Select an option...',
      }}
      style={style}
      className={className}
      required={required}
      selection={selectedMapped ? [selectedMapped] : []}
      events={{
        onSelect: (
          event: React.ChangeEvent,
          { selection }: PicklistSelectValue
        ) => {
          const id = selection[0].id;
          setSelected(id);
          onChange({
            ...field,
            value: id,
          });
        },
      }}
    />
  );
}
