import React, { Fragment } from 'react';
import cx from 'classnames';
import PropTypes from 'prop-types';
import Icon from '@salesforce/design-system-react/lib/components/icon';
import Button from '@salesforce/design-system-react/lib/components/button';

import Avatar from './Avatar';

import { messageType, bookendStatus } from './enum';

const propTypes = {
  id: PropTypes.string,
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  avatar: PropTypes.node,
  type: PropTypes.oneOf(Object.values(messageType)),
  meta: PropTypes.node,
  metaAriaLabel: PropTypes.string,
  // for outbound error
  isError: PropTypes.bool,
  errorHeader: PropTypes.node,
  redoButtonText: PropTypes.string,
  redoButtonAction: PropTypes.func,
  // for event
  icon: function(props, propName) {
    if (
      props['type'] === messageType.BOOKEND &&
      typeof props[propName] !== 'string'
    ) {
      return new Error('Please provide a valid `icon`!');
    }
  },
  agentMessage: PropTypes.node,
  // bookend
  status: function(props, propName) {
    if (
      props['type'] === messageType.BOOKEND &&
      !Object.values(bookendStatus).includes(props[propName])
    ) {
      return new Error('Please provide a valid `status`!');
    }
  },
  messageBeforeMeta: PropTypes.bool,
};

const defaultProps = {
  meta: null,
  metaAriaLabel: null,
  avatar: null,
  isError: false,
  redoButtonText: 'Resend',
  redoButtonAction: () => {},
  agentMessage: null,
  messageBeforeMeta: true,
  id: null,
};

function Message({
  children,
  className,
  avatar,
  type,
  meta,
  metaAriaLabel,
  isError,
  errorHeader,
  redoButtonText,
  redoButtonAction,
  icon,
  agentMessage,
  status,
  messageBeforeMeta,
  id,
}) {
  const msgAvatar = type === messageType.INBOUND && avatar && (
    <Avatar className="slds-chat-avatar" {...avatar.props} />
  );

  const metaContent = meta && (
    <div className="slds-chat-message__meta" aria-label={metaAriaLabel}>
      {meta}
    </div>
  );

  const outBoundError = type === messageType.OUTBOUND && isError && (
    <Fragment>
      <div className="slds-chat-message__text slds-chat-message__text_delivery-failure">
        {errorHeader && <span>{errorHeader}</span>}
        <div
          className="slds-chat-message__text_delivery-failure-reason"
          role="alert"
        >
          <Icon
            containerClassName="slds-icon-utility-error slds-chat-icon"
            assistiveText={{ label: 'Error' }}
            category="utility"
            name="error"
            size="x-small"
          />

          <span>{children}</span>
        </div>
      </div>
      <div className="slds-grid slds-grid_align-spread slds-grid_vertical-align-start">
        {metaContent}
        <Button
          className="slds-chat-message__action slds-m-top_xxx-small"
          iconCategory="utility"
          iconName="redo"
          iconPosition="left"
          label={redoButtonText}
          variant="base"
          onClick={redoButtonAction}
        />
      </div>
    </Fragment>
  );

  const chatIcon = icon && (
    <Icon
      containerClassName={`slds-icon_container slds-icon-utility-${icon} slds-chat-icon`}
      category="utility"
      name={icon}
      size="x-small"
    />
  );

  const event = type === messageType.EVENT && (
    <div className="slds-chat-event">
      <div className="slds-chat-event__rule" />
      <div className="slds-chat-event__body">
        {chatIcon}
        <p>{children}</p>
      </div>
      <div className="slds-chat-event__rule" />
      {agentMessage && (
        <div className="slds-chat-event__agent-message">{agentMessage}</div>
      )}
    </div>
  );

  const bookend = type === messageType.BOOKEND && (
    <div className={`slds-chat-bookend slds-chat-bookend_${status}`}>
      {chatIcon}
      <p>{children}</p>
    </div>
  );

  const messageBody = (
    <Fragment>
      {!messageBeforeMeta && metaContent}
      <div
        className={cx(
          'slds-chat-message__text',
          `slds-chat-message__text_${type}`
        )}
      >
        <span>{children}</span>
      </div>
      {messageBeforeMeta && metaContent}
    </Fragment>
  );

  const message = (
    <div className="slds-chat-message" id={id}>
      {msgAvatar}
      <div className="slds-chat-message__body">
        {outBoundError || messageBody}
      </div>
    </div>
  );

  return (
    <li
      className={cx(
        'slds-chat-listitem',
        `slds-chat-listitem_${type}`,
        className
      )}
    >
      {event || bookend || message}
    </li>
  );
}

Message.propTypes = propTypes;
Message.defaultProps = defaultProps;
export default Message;
