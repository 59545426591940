import React, { useState, useMemo } from 'react';
import { useDropzone, Accept } from 'react-dropzone';
import cx from 'classnames';
import capitalize from 'lodash/capitalize';
import Icon from '@salesforce/design-system-react/lib/components/icon';
import Spinner from '@salesforce/design-system-react/lib/components/spinner';

import { HandleUploadObject } from '../types';

import styles from './FileUpload.module.scss';

interface VariantsType {
  [key: string]: any;
}
export const VARIANTS: VariantsType = {
  file: 'slds-file-selector_files',
  image: 'slds-file-selector_images',
};

interface Props {
  id?: string;
  src?: string;
  variant?: string;
  multiple?: boolean;
  label?: string;
  buttonLabel?: string;
  allowedTypes?: Accept;
  onUpload?: (args: HandleUploadObject) => void;
  onReject?: () => void;
}

function FileUpload({
  id = '',
  src = '',
  variant = 'file',
  label = 'Logo',
  buttonLabel = 'Upload Icon',
  allowedTypes = { 'image/*': [] },
  multiple = false,
  onUpload,
  onReject,
}: Props) {
  const [isDropRejected, setIsDropRejected] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const { getRootProps, getInputProps, isDragActive, isDragReject } =
    useDropzone({
      accept: allowedTypes,
      onDragEnter: handleOnDragEnter,
      onDropAccepted: handleOnDropAccept,
      onDropRejected: handleOnDropReject,
      multiple,
      minSize: 0,
    });

  const rootClassName = useMemo(
    () =>
      cx('slds-file-selector__dropzone', styles.relative, {
        'slds-has-drag-over': isDragActive,
      }),
    [isDragActive]
  );

  async function handleOnDropAccept(event: any) {
    if (typeof onUpload === 'function') {
      const [file] = event;
      setIsSaving(true);
      await onUpload({ data: file, id });
      setIsSaving(false);
      setIsDropRejected(false);
    }
  }

  function handleOnDragEnter() {
    setIsDropRejected(false);
  }

  function handleOnDropReject() {
    setIsDropRejected(true);

    if (typeof onReject === 'function') {
      onReject();
    }
  }

  return (
    <div
      className={cx('slds-form-element', {
        'slds-has-error': isDragReject || isDropRejected,
      })}
    >
      <span
        className="slds-form-element__label"
        id="file-selector-primary-label"
      >
        {label}
      </span>

      <div className="slds-grid slds-gutters">
        <div className="slds-col slds-size_1-of-4">
          <div className="slds-form-element__control">
            <div className={cx('slds-file-selector', VARIANTS[variant])}>
              <div
                {...getRootProps({
                  className: rootClassName,
                })}
              >
                <input
                  {...getInputProps({
                    className: 'slds-file-selector__input slds-assistive-text',
                    id: `file-upload-input-${id}`,
                    disabled: isDragActive && isDragReject,
                  })}
                  aria-labelledby="file-selector-primary-label file-selector-secondary-label"
                />
                <label
                  className="slds-file-selector__body"
                  htmlFor="file-upload-input"
                  id="file-selector-secondary-label"
                >
                  {isSaving && (
                    <Spinner
                      size="small"
                      assistiveText={{ label: 'Uploading...' }}
                    />
                  )}
                  <span
                    className={cx(
                      'slds-file-selector__button slds-button slds-button_neutral',
                      styles.nowrap
                    )}
                  >
                    <Icon
                      assistiveText={{ label: 'Upload' }}
                      category="utility"
                      name="upload"
                      size="xx-small"
                      className="slds-button__icon slds-button__icon_left"
                      colorVariant={
                        isDragActive && isDragReject ? 'light' : 'default'
                      }
                    />
                    {buttonLabel || `Upload ${capitalize(variant)}`}
                  </span>
                  <span
                    className={cx(
                      'slds-file-selector__text slds-medium-show',
                      styles.nowrap
                    )}
                  >
                    or Drop {capitalize(variant)}
                  </span>
                </label>
              </div>
            </div>
          </div>
          {isDragReject && (
            <div className="slds-form-element__help">
              File type not supported
            </div>
          )}
          {isDropRejected && (
            <div className="slds-form-element__help">
              File rejected. Please ensure that the file have a valid file type.
            </div>
          )}
        </div>
        <div className="slds-col">
          <div
            className={styles.previewContainer}
            style={{
              backgroundImage: src ? `url(${src})` : 'none',
            }}
          />
        </div>
      </div>
    </div>
  );
}

export default FileUpload;
