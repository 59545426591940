export default function iconMapper(type: string) {
  switch (String(type).toLowerCase()) {
    case 'docx':
    case 'doc':
      return 'word';

    case 'xls':
    case 'xlsx':
      return 'excel';

    case 'pdf':
      return 'pdf';

    case 'cmx':
    case 'cod':
    case 'gif':
    case 'ico':
    case 'ief':
    case 'jfif':
    case 'jpe':
    case 'jpeg':
    case 'jpg':
    case 'pbm':
    case 'pgm':
    case 'pnm':
    case 'png':
    case 'ppm':
    case 'ras':
    case 'svg':
    case 'tif':
    case 'tiff':
    case 'webp':
    case 'xbm':
    case 'xpm':
    case 'xwd':
    case 'bmp':
      return 'image';

    case 'txt':
      return 'txt';

    case 'htm':
    case 'html':
      return 'html';

    default:
      return 'unknown';
  }
}
