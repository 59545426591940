import React, { useState, useReducer, useMemo } from 'react';
import cx from 'classnames';
import Spinner from '@salesforce/design-system-react/lib/components/spinner';
import Icon from '@salesforce/design-system-react/lib/components/icon';
import { loadStripe } from '@stripe/stripe-js';
import { Elements, CardElement } from '@stripe/react-stripe-js';
import omit from 'lodash/omit';

import { Plugin, PaymentMethod } from 'containers/Site/types';
import PaymentHeader from './component/PaymentHeader';
import PaymentBody from './component/PaymentBody';
import PaymentFooter from './component/PaymentFooter';
import ToastyManager from 'containers/Toasty/ToastyManager';
import { Step, User, Payload, PaymentDetailsProps } from './types';
import STEPS from './constants/paymentSteps';
import env from 'helpers/env';
import { getCountryFromIso } from 'helpers/isoCountries';
import api from 'helpers/api';

import styles from './Payment.module.scss';

function getSteps(plugin: Plugin): Step[] {
  const paymentMethod = plugin.paymentMethod
    ? plugin.paymentMethod
    : ({} as any);

  let steps = Object.keys(STEPS).map((key: string) => ({
    id: key,
    label: STEPS[key],
  }));

  if (paymentMethod.type === 'cardonly' || !paymentMethod.type) {
    steps = steps.slice(1, steps.length);
  }

  if (!paymentMethod.acceptBillingContact) {
    steps = [
      ...steps.slice(0, steps.length - 2),
      ...steps.slice(steps.length - 1, steps.length),
    ];
  }

  return steps;
}

const initialState: Payload & PaymentDetailsProps = {
  orgid: '',
  origin: '',
  roomid: '',
  crmid: '',
  username: '',
  itemid: '',
  totalAmount: '0',
  contacts: [],
  paymentType: 'card',
};

function reducer(
  state: Payload & PaymentDetailsProps,
  payload: Payload & PaymentDetailsProps
) {
  return { ...state, ...payload };
}

interface Props {
  orgId: string;
  roomId: string;
  memberId?: string;
  user: User;
  plugin: Plugin;
  apiKey: string;
  setPage: (page: string) => void;
}

const Payment = ({
  memberId,
  orgId,
  roomId,
  user,
  plugin,
  apiKey,
  setPage,
}: Props) => {
  const [state, dispatch] = useReducer(reducer, {
    ...initialState,
    firstName: user.firstName,
    lastName: user.lastName,
    email: user.email,
    billingStreet: user.billingStreet,
    billingCity: user.billingCity,
    billingState: user.billingState,
    billingPostalCode: user.billingPostalCode,
    billingCountry: user.billingCountry,
    contacts: [
      {
        firstName: user.firstName,
        lastName: user.lastName,
        email: user.email,
        street: user.billingStreet,
        city: user.billingCity,
        state: user.billingState,
        postalCode: user.billingPostalCode,
        country: user.billingCountry,
      },
    ],
    orgid: orgId,
    roomid: roomId,
    username: user.username,
    totalAmount:
      (plugin.paymentMethod && (plugin.paymentMethod as PaymentMethod).value) ||
      '0',

    // ACH-specific fields that is pre-populated
    bank_account_type: 'Business Checking',
    account_holder_type: 'Company',
  });
  const [error, setError] = useState(null);
  const [step, setStep] = useState(0);
  const [isLoading, setLoading] = useState(false);
  const [showPaymentForm, setShowPaymentForm] = useState(true);
  const [responseMessage, setResponseMessage] = useState('');
  const action =
    plugin.actions && plugin.actions.length > 0
      ? plugin.actions[0]
      : { displayLabel: plugin.displayLabel };
  const steps = getSteps(plugin);
  let stripeKey = apiKey;

  if (!stripeKey && process.env.NODE_ENV === 'development') {
    stripeKey = env('REACT_APP_PAYMENT_STRIPE_TEST_API_KEY', null);
  }

  const stripePromise = useMemo(() => loadStripe(stripeKey), [stripeKey]);
  const handleStepChange = (step: number) => setStep(step);
  const handleOnChange = (payload: Payload & PaymentDetailsProps) => {
    dispatch(payload);
  };
  const handlePay = async (stripe: any, elements: any) => {
    const { firstName, lastName, username } = user;

    setLoading(true);

    let stripeResponse: any = {};
    if (state.paymentType === 'card') {
      try {
        const cardElement = elements?.getElement(CardElement);

        if (!cardElement) throw new Error('Card Element is undefined');
        if (!stripe) throw new Error('Card Element is undefined');

        stripeResponse = await stripe.createToken(cardElement, {
          name: `${firstName} ${lastName}`,
          address_line1: state.billingStreet,
          address_city: state.billingCity,
          address_state: state.billingState,
          address_zip: state.billingPostalCode,
          address_country: getCountryFromIso(state.billingCountry),
          currency: 'usd',
        });
      } catch (error) {
        ToastyManager.emit({
          variant: 'error',
          message: error instanceof Error ? error.message : String(error),
        });

        console.error(error);
      }
    }

    if (stripeResponse.error) {
      ToastyManager.emit({
        variant: 'error',
        message: stripeResponse.error.message,
      });

      console.error(stripeResponse);
    } else {
      setError(null);

      const paymentFields = {
        card: ['token'],
        ach: [
          'bank_name',
          'routing_number',
          'account_number',
          'bank_account_type',
          'account_holder_type',
        ],
        po: ['purchaseOrderNumber', 'poInstructions'],
      };
      let fieldsToOmit: string[] = ['contacts', 'paymentType'];
      switch (state.paymentType) {
        case 'card':
          fieldsToOmit = [
            ...fieldsToOmit,
            ...paymentFields.ach,
            ...paymentFields.po,
          ];
          break;

        case 'ach':
          fieldsToOmit = [
            ...fieldsToOmit,
            ...paymentFields.card,
            ...paymentFields.po,
          ];
          break;
        case 'po':
          fieldsToOmit = [
            ...fieldsToOmit,
            ...paymentFields.card,
            ...paymentFields.ach,
          ];
      }

      let data: any = {
        ...omit(state, fieldsToOmit),
        contacts: (state.contacts as any[]).map((contact: any) =>
          omit(contact, ['copyPersonal'])
        ),
        orgid: orgId,
        origin: 'web',
        roomid: roomId,
        crmid: user.crmId,
        username,
        itemid: plugin.id,
        memberid: memberId,
      };

      if (state.paymentType === 'card') {
        data.token = stripeResponse;
      }

      if (
        (typeof plugin.paymentMethod !== 'undefined' &&
          typeof plugin.paymentMethod.value !== 'undefined') ||
        typeof state.totalAmount !== 'undefined'
      ) {
        data.totalAmount =
          typeof data.totalAmount !== 'undefined'
            ? data.totalAmount
            : (plugin.paymentMethod as PaymentMethod).value;
      }

      try {
        const action = plugin.actions ? plugin.actions[0] : {};
        const response = await api.post('/payments', data);

        if (response.redirectURL) {
          window.location.href = response.redirectURL;
        } else if (action && action.nextPage) {
          // Show next page
          setPage(action.nextPage);
        } else {
          setShowPaymentForm(!response.hidePaymentForm);
          setResponseMessage(
            plugin.successMessage ? plugin.successMessage : response.message
          );
        }
      } catch (error) {
        console.error(error);
      }
    }

    setLoading(false);
  };

  return (
    <div className={cx('slds-p-horizontal_small', styles.contentContainer)}>
      {/* @TODO: Checklist support */}
      <div className={styles.pluginContainer}>
        <div
          className={cx(
            styles.formContainer,
            plugin.alignment !== 'center'
              ? `slds-float_${plugin.alignment}`
              : 'slds-align_absolute-center'
          )}
        >
          <Elements stripe={stripePromise}>
            <div>
              {isLoading && <Spinner size="x-small" />}
              {showPaymentForm && (
                <>
                  <PaymentHeader
                    step={step}
                    steps={steps}
                    finalButtonLabel={plugin.displayLabel}
                    onRequestStepChange={handleStepChange}
                    onRequestPay={handlePay}
                  />
                  <PaymentBody
                    stepId={steps[step].id}
                    plugin={plugin}
                    state={state}
                    onChange={handleOnChange}
                    stripeKey={stripeKey}
                  />
                  <PaymentFooter
                    step={step}
                    steps={steps}
                    action={action}
                    finalButtonLabel={action.displayLabel}
                    onRequestStepChange={handleStepChange}
                    onRequestPay={handlePay}
                  />
                </>
              )}
              {responseMessage && (
                <div className="slds-text-align_center">
                  <Icon
                    assistiveText={{
                      label: error ? 'Payment Failed' : 'Successful Payment',
                    }}
                    category="action"
                    name={error ? 'close' : 'approval'}
                    size="small"
                  />
                  <p className="slds-m-top_small">
                    {error ? error : responseMessage}
                  </p>
                </div>
              )}
            </div>
          </Elements>
        </div>
      </div>
    </div>
  );
};

export default Payment;
