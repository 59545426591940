import unionBy from 'lodash/unionBy';
import { State, Message } from './types';

export function setMessages(state: State, messages: Message[]) {
  return {
    ...state,
    messages,
  };
}

export function toggleChat(state: State) {
  return {
    ...state,
    show: !state.show,
  };
}

export function addMessage(state: State, message: any): State {
  return {
    ...state,
    messages: [...state.messages, message],
  };
}

export function updateMessage(state: State, updatedMessage: Message): State {
  const messages: Message[] = state.messages.map((message: Message) => {
    return message.id === updatedMessage.tempId
      ? {
          ...message,
          ...updatedMessage,
        }
      : message;
  });

  return {
    ...state,
    messages,
  };
}

export function addMessages(state: State, messages: Message[]): State {
  return {
    ...state,
    messages: unionBy(state.messages, messages, 'id'),
  };
}

export function setLastActive(state: State, lastActive: any): State {
  return {
    ...state,
    lastActive,
  };
}
export function setUnreadNum(state: State, unread: number): State {
  return {
    ...state,
    unread,
  };
}
export function addUnreadNum(state: State, unread: number): State {
  return {
    ...state,
    unread: state.unread + unread,
  };
}

export function setInitialized(state: State, isInitialized: boolean): State {
  return {
    ...state,
    isInitialized,
  };
}
