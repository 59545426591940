import React, { useState } from 'react';
import Icon from '@salesforce/design-system-react/lib/components/icon';
import get from 'lodash/get';

import styles from './Image.module.scss';

const getAlignment = (alignment: string): string => {
  switch (alignment) {
    case 'left':
      return 'flex-start';

    case 'right':
      return 'flex-end';

    default:
      return alignment;
  }
};

interface IProps {
  plugin: any;
}

export default function Image({ plugin }: IProps) {
  const [hasError, setError] = useState(false);
  const handleError = () => {
    setError(true);
  };

  return (
    <div
      className={styles.container}
      style={{ alignItems: getAlignment(plugin.alignment) }}
    >
      {hasError || get(plugin, ['contentURL'], '') === '' ? (
        <div className={styles.placeholderContainer}>
          <Icon
            category="doctype"
            name="image"
            className="slds-m-bottom_x-small"
          />
        </div>
      ) : (
        <img
          src={get(plugin, ['contentURL'], '')}
          onError={handleError}
          style={{
            width: plugin.width ? Number(plugin.width) : 'auto',
            height: plugin.height ? Number(plugin.heigh) : 'auto',
          }}
          alt={get(plugin, ['contentURL'], '')}
        />
      )}
    </div>
  );
}
