interface KVP {
  [key: string]: string;
}

export const KEYS: KVP = {
  AMOUNT: 'amount',
  PERSONAL_DETAILS: 'personal_details',
  BILLING_DETAILS: 'billing_details',
  PAYMENT_DETAILS: 'payment_details',
};

const VALUES: KVP = {
  amount: 'Amount',
  personal_details: 'Personal Info',
  billing_details: 'Billing Info',
  payment_details: 'Payment',
};

export default VALUES;
