/**
 * Just got this aha moment while showering. Gonna make this epic.
 * A simple Observable pattern that should be super simple to understand.
 * Debugging and improving this thing shouldn't require you to have
 * a CS degree and a master of algos, patterns, and all the fancy jargons.
 *
 * This should remove the redux dependency of our Toasty Salesforce React Toast stuff
 *
 * From Dan. K. Thx. Bye.
 */

export let LOG_SEVERITY = 'warn'; // valid value could be 'off' | 'warn' | 'error'

interface IEmitParams {
  variant?: string;
  type?: string;
  message?: string;
  ident?: number;
}

class ToastyManager {
  callbacks: Function[] = [];

  on = (cb: Function) => {
    // this.callbacks is accessible outside, and is mutable... it'll be pretty bad
    // if Toasty itself threw errors and broke the whole thing just because someone
    // pretty smart decided to overwrite ToastyManager.callbacks
    if (Array.isArray(this.callbacks)) {
      this.callbacks.push(cb);
    } else {
      this.callbacks = [cb];
    }
  };

  off = (cb: Function) => {
    if (Array.isArray(this.callbacks)) {
      const index = this.callbacks.indexOf(cb);

      // Did ~index for a random TIL moment for you, future maintainer...
      // That is if you never encountered this before. ~-1 would equal 0, which is a falsey value.
      if (~index) {
        // We would need to slice stuff. Doing a simple `delete this.callback[index] would not work
        // it would have some left over `empty` value in the array.
        this.callbacks = [
          ...this.callbacks.slice(0, index),
          ...this.callbacks.slice(index + 1, this.callbacks.length + 1),
        ];
      } else if (LOG_SEVERITY !== 'off') {
        // eslint-disable-next-line no-console
        console[LOG_SEVERITY === 'error' ? 'error' : 'warn'](
          `Function ${cb} does not exist in the callbacks array`
        );
      }
    }
  };

  emit = ({
    variant = 'error',
    type = 'alert',
    message = 'Unknown Error',
    ...rest
  }: IEmitParams) => {
    if (Array.isArray(this.callbacks)) {
      this.callbacks.forEach((cb) => {
        if (typeof cb === 'function') {
          cb({ variant, type, message, ...rest });
        }
      });
    }
  };
}

const instance = new ToastyManager();

export default instance;
