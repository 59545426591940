import React, { useEffect, useRef, useState } from 'react';

import styles from './Checklist.module.scss';

interface Props {
  plugin: any;
  room: any;
}

const htmlScript = `
<link rel="stylesheet" type="text/css" href="slds/assets/styles/salesforce-lightning-design-system.css?v=1">
<link rel="stylesheet" type="text/css" href="static/css/checklist.css?v=1">
<style>html{background: #fff!important;}iframe{height: 100%!important;}</style>

<div id="app" class="wrapper">
    <div class="slds-box">

      <div class="slds-grid slds-grid_align-center">
        <div class="slds-col">
          <div class="slds-text-heading_large slds-m-bottom_x-small title"></div>
        </div>
      </div>
      <div class="slds-grid slds-grid_align-center">
        <div class="slds-col">
          <button onclick="onNavStatusClick(this, null);" class="slds-button slds-text-heading_small slds-m-bottom_x-small subtitle">Show All Items (<span></span>)</button>
        </div>
      </div>

      <div id="navigation" class="slds-grid slds-grid_pull-padded-medium slds-grid_align-center slds-m-top_x-small slds-wrap slds-grid_vertical-align-center">

      </div>

      <div id="checklist" class="checklist slds-m-top_medium">

      </div>
    </div>
    <div id="LoadingSpinner" class="slds-spinner_container" style="height:6rem; display: none">
       <div role="status" class="slds-spinner slds-spinner_medium slds-spinner_brand">
         <span class="slds-assistive-text">Loading</span>
         <div class="slds-spinner__dot-a"></div>
         <div class="slds-spinner__dot-b"></div>
       </div>
    </div>
</div>

<script>
  var script  = document.createElement('script');
  script.src  ='static/js/checklist.js?v=115';
  document.body.appendChild(script);
  script.onload = function(){
    if(typeof roomContext !== 'undefined' && typeof roomContext.checklistId !== 'undefined' && roomContext.checklistId !== ''){
      checklistInit( roomContext.checklistId );
    } else {
      hideChecklist();
    }
  }

  function hideChecklist() {
    var x = document.getElementById("app");
    if (typeof x !== 'undefined') {
      document.querySelector('.slds-box').style.display='block'
      x.style.display = "none";
    }
  }

</script>
`;

function Checklist({ room, plugin }: Props) {
  const iframeRef = useRef<HTMLIFrameElement>(null);
  const roomRef = useRef<any>(room);
  const [height, setHeight] = useState(0);

  useEffect(() => {
    if (!iframeRef.current) return;

    const parser = new DOMParser();
    const roomContext = `<script>var roomContext=${JSON.stringify(
      roomRef.current
    )}</script>`;
    const dom = parser.parseFromString(roomContext + htmlScript, 'text/html');

    const documentResizeScript = dom.createElement('script');
    documentResizeScript.innerHTML = `
      window.parent.postMessage({
        id: '${plugin.name}',
        height: document.body.clientHeight,
        width: document.body.clientWidth,
      }, '${window.location.origin}');
    `;

    dom.body.appendChild(documentResizeScript);
    dom.body.style.margin = '0';

    iframeRef.current.setAttribute('srcdoc', dom.documentElement.outerHTML);
  }, [plugin.name]);

  useEffect(() => {
    setHeight(typeof plugin.height === 'undefined' ? 1000 : plugin.height);
  }, [plugin.height]);

  return (
    <iframe
      title={plugin.name}
      src="about:blank"
      ref={iframeRef}
      className={styles.iframe}
      style={{
        height,
        width: plugin.width ? plugin.width : '100%',
      }}
    />
  );
}

export default Checklist;
