import * as reducers from './reducers';
import effects from './effects';
import { Message } from './types';

const ChatModel = {
  state: {
    messages: [] as Message[],
    show: false,
    lastActive: null,
    unread: 0,
    isInitialized: false,
  },
  reducers,
  effects,
};

export default ChatModel;
