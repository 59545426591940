export const messageType = {
  INBOUND: 'inbound',
  OUTBOUND: 'outbound',
  EVENT: 'event',
  BOOKEND: 'bookend',
};

export const bookendStatus = {
  START: 'start',
  STOP: 'stop',
};
