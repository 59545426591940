import React from 'react';
import Button from '@salesforce/design-system-react/lib/components/button';
import { useElements, useStripe } from '@stripe/react-stripe-js';

import { Step } from '../types';

import styles from './PaymentFooter.module.scss';

interface Props {
  step: number;
  steps: Step[];
  action: any;
  finalButtonLabel?: string;
  onRequestStepChange: (step: number) => void;
  onRequestPay: (stripe: any, elements: any) => void;
}

export default function PaymentFooter({
  step,
  steps,
  finalButtonLabel,
  onRequestStepChange,
  onRequestPay,
  action,
}: Props) {
  const stripe = useStripe();
  const elements = useElements();
  const label = step < steps.length - 1 ? 'Next' : finalButtonLabel || 'Pay';
  const variant = action.displayType === 'BUTTON' ? 'neutral' : 'link';
  const displayColor =
    action.displayType === 'BUTTON' ? action.displayColor : 'transparent';
  const fontColor =
    action.displayType === 'BUTTON'
      ? action.displayFontColor || '#FFF'
      : '#0070d2';

  const handleNext = () => {
    const next = step + 1;

    if (next < steps.length) {
      onRequestStepChange(next);
    } else {
      onRequestPay(stripe, elements);
    }
  };

  return (
    <Button
      className={styles.payButton}
      label={label}
      variant={typeof action.displayType === 'undefined' ? 'brand' : variant}
      onClick={handleNext}
      style={{
        backgroundColor:
          typeof action.displayType === 'undefined' ? '#0070d2' : displayColor,
        color: typeof action.displayType === 'undefined' ? '#fff' : fontColor,
      }}
    />
  );
}
