import React, { useEffect, useState } from 'react';

import styles from './Text.module.scss';

interface Props {
  value: string;
}

function Text({ value }: Props) {
  const [__html, setHtml] = useState(value);

  useEffect(() => {
    const parser = new DOMParser();
    const dom = parser.parseFromString(
      `<div>${value}</div>`,
      'application/xml'
    );

    Array.from(dom.querySelectorAll('a')).forEach((link) => {
      link.setAttribute('target', '_blank');
    });

    const str = dom.documentElement.outerHTML.replace(
      /<(p|span)[^>]*><\/(p|span)>/gi,
      (original, p1, p2) => {
        if (p1 !== p2) return original;
        return `<${p1}>&#65279;</${p2}>`;
      }
    );

    setHtml(str);
  }, [value]);

  return (
    <div className={styles.container} dangerouslySetInnerHTML={{ __html }} />
  );
}

export default Text;
