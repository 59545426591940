import axios, { AxiosRequestConfig } from "axios";
import get from "lodash/get";

import { ToastyManager } from "containers/Toasty";
import env from "helpers/env";

export const URL = env("REACT_APP_API_URL", "https://api.i-dialogue.com/v1");

const baseConfig: AxiosRequestConfig = {
  baseURL: URL,
};

let api = axios.create(baseConfig);

export function updateConfig(config: AxiosRequestConfig = baseConfig) {
  api = axios.create({ ...baseConfig, ...config });
}

const idlogApi = {
  get: async (path: string, suppressToast = false) => {
    if (
      process.env.NODE_ENV === "development" &&
      localStorage.getItem(`get-${path}`) &&
      path.indexOf("/messages") === -1
    ) {
      const data: string = localStorage.getItem(`get-${path}`) as string;
      return JSON.parse(data);
    }

    try {
      const { data, status } = await api.get(path);

      if (
        process.env.NODE_ENV === "development" &&
        path.indexOf("/messages") === -1
      ) {
        localStorage.setItem(`get-${path}`, JSON.stringify(data));
      }

      if (!data.success) {
        throw new Error(data.message);
      }

      return { ...data, statusCode: status };
    } catch (error) {
      if (!suppressToast) {
        const message = get(
          error,
          ["response", "data", "message"],
          error instanceof Error ? error.message : String(error)
        );
        ToastyManager.emit({ variant: "error", message });
      }

      return Promise.reject(error);
    }
  },
  post: async (path: string, params: any = {}, suppressToast = false) => {
    if (
      process.env.NODE_ENV === "development" &&
      localStorage.getItem(`post-${path}`) &&
      path.indexOf("/messages") === -1 &&
      path.indexOf("/forms") === -1 &&
      path.indexOf("/items") === -1
    ) {
      const data: string = localStorage.getItem(`post-${path}`) as string;
      return JSON.parse(data);
    }

    try {
      const { data, status } = await api.post(path, params);

      if (
        process.env.NODE_ENV === "development" &&
        path !== "/auth/tokens/redeem" &&
        path.indexOf("/messages") === -1 &&
        path.indexOf("/items") === -1
      ) {
        localStorage.setItem(`post-${path}`, JSON.stringify(data));
      }

      if (!data.success) {
        throw new Error(data.message);
      }

      return { ...data, statusCode: status };
    } catch (error) {
      if (!suppressToast) {
        const message = get(
          error,
          ["response", "data", "message"],
          error instanceof Error ? error.message : String(error)
        );
        ToastyManager.emit({ variant: "error", message });
      }

      return Promise.reject(error);
    }
  },
  delete: async (path: string, params: any = {}, suppressToast = false) => {
    try {
      const { data } = await api.delete(`${URL}${path}`, { data: params });

      if (!data.success) {
        throw new Error(data.message);
      }

      return data;
    } catch (error) {
      if (!suppressToast) {
        const message = get(
          error,
          ["response", "data", "message"],
          error instanceof Error ? error.message : String(error)
        );
        ToastyManager.emit({ variant: "error", message });
      }

      return Promise.reject(error);
    }
  },
};

export default idlogApi;
