import React from 'react';
import get from 'lodash/get';

import PaymentForm from '../component/PaymentForm';
import { Payload, PaymentDetailsProps, PaymentType } from '../types';
import { Plugin } from 'containers/Site/types';

interface Props {
  state: Payload & PaymentDetailsProps;
  plugin: Plugin;
  stripeKey: string;
  onChange: (payload: Payload & PaymentDetailsProps) => void;
}

const PaymentTypes: PaymentType[] = ['card', 'ach', 'po'];

function CardScreen({
  state,
  plugin,
  stripeKey,
  onChange,
}: Props) {

  const {
    paymentMethod = {
      acceptACH: false,
      acceptPO: false,
    },
  } = plugin;

  let paymentMethods = ['card'];

  if (paymentMethod.acceptACH) {
    paymentMethods.push('ach');
  }

  if (paymentMethod.acceptPO) {
    paymentMethods.push('po');
  }

  const handlePaymentFieldChange = (field: string, value: string) => {
    onChange({
      [field]: value,
    });
  };

  const handleTabChange = (index: number) => {
    onChange({
      paymentType: PaymentTypes[index],
    });
  };

  const handlePaymentComboboxChange = (field: string, data: any) => {
    const value = get(data, ['selection', 0, 'id'], '');
    onChange({
      [field]: value,
    });
  };

  return (
    <div className="slds-m-top_medium">
      <PaymentForm
        methods={paymentMethods}
        values={state}
        onFieldsChange={handlePaymentFieldChange}
        onTabChange={handleTabChange}
        onComboboxChange={handlePaymentComboboxChange}
        hasStripeKey={!!stripeKey}
      />
    </div>
  );
}

export default CardScreen;
