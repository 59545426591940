import { State, KeyValuePair } from './types';

export function update(state: State, params: KeyValuePair) {
  return {
    ...state,
    [params.key]: params.value,
  };
}

export function modify(state: State, params: any) {
  return {
    ...state,
    ...params,
  };
}

export function deletePlugin(state: State, id: string) {
  const targetPlugin: any = Object.entries(state.plugins).find(
    ([key, plugin]: any) =>
      plugin.id === id || (plugin.item && plugin.item.id === id)
  );
  const pluginKey: string = targetPlugin && targetPlugin[0];

  if (pluginKey) {
    const { [pluginKey]: value, ...plugins } = state.plugins;

    return {
      ...state,
      plugins,
    };
  }

  return state;
}
