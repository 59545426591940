import { normalize } from 'normalizr';
import pick from 'lodash/pick';
import pickBy from 'lodash/pickBy';
import omit from 'lodash/omit';

import { ToastyManager } from 'containers/Toasty';

import api from 'helpers/api';
import pagesScheme from 'schemas/pages';
import { HandleUploadObject } from 'plugins/FileUpload/types';

const fallbackFileTypes = [
  'application/msword',
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
  'application/pdf',
  'image/jpeg',
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  'application/vnd.ms-excel',
  'text/plain',
  'text/html',
];

interface FileUploadObject extends HandleUploadObject {
  pageId: string;
  body: string;
}

export default function SiteEffects(dispatch: any) {
  return {
    async getSite(roomId: string, rootState: any) {
      const { memberId } = rootState.app;

      try {
        const result = await api.get(`/rooms/${roomId}/portal`);

        const { site, room, message, statusCode } = result;

        if (statusCode === 206) {
          dispatch.app.update({ key: 'showTwoFactor', value: true });
          dispatch.app.update({ key: 'twoFactorMessage', value: message });
          return; // Apparantly, result won't have the actual site details at this point, so do an early exit
        }

        const { entities } = normalize(site || {}, pagesScheme) as any;
        const supportedFileTypes = site.supportedFileTypes
          ? String(site.supportedFileTypes).split(',')
          : fallbackFileTypes;

        dispatch.site.update({
          key: 'pages',
          value: Object.values(entities.pages).filter(
            (page: any) =>
              page.showInNavigation || page.showInNavigation === undefined
          ),
        });

        const plugins = pickBy(entities.plugins, (p: any) => !p.isDeleted);

        dispatch.site.update({ key: 'plugins', value: plugins });
        dispatch.site.modify({
          ...pick(site, [
            'id',
            'name',
            'label',
            'displayLabel',
            'showHeader',
            'showFooter',
            'backgroundColor',
            'itemDeleteAccess',
            'messagingAccess',
            'logoURL',
            'sobjectType',
            'esign',
            'options',
            'defaultRoomMessage',
            'defaultAutoResponse',
          ]),
          supportedFileTypes,
        });

        if (room) {
          dispatch.app.update({
            key: 'room',
            value: omit(room, ['items', 'm_messages', 'members']),
          });
        }

        // Get additional member details
        if (room && room.members) {
          const member = room.members.find((item: any) => item.id === memberId);

          if (member) {
            dispatch.app.deepModify({
              key: 'user',
              value: pick(member, [
                'billingCity',
                'billingCountry',
                'billingPostalCode',
                'billingState',
                'billingStreet',
              ]),
            });
          }
        }
      } catch (error) {
        console.error(error);
      }
    },
    async fileUpload(
      { pageId, id: pluginId, data: fileData }: FileUploadObject,
      rootState: any
    ) {
      const { roomId } = rootState.app;

      try {
        const data = {
          roomId,
          pageId,
          pluginId,
          origin: 'web',
          title: fileData.name.split('.')[0],
          description: '',
          fileName: fileData.name,
          contentType: fileData.type,
          size: fileData.size,
          addItem: true,
        };

        const { item } = await api.post(`/rooms/${roomId}/items`, data);
        if (item && item.signedURL) {
          const unicodes = [
            { code: '\\u003d', value: '=' },
            { code: '\\u0026', value: '&' },
          ];

          const signedUrl = unicodes.reduce((text, rule) => {
            const regex = new RegExp(rule.code, 'gi');
            return text.replace(regex, rule.value);
          }, item.signedURL);

          // Upload file to signedURL
          await fetch(signedUrl, {
            method: 'PUT',
            headers: {
              'Content-Type': item.contentType,
            },
            body: fileData,
          }).catch((_) => {
            ToastyManager.emit({
              variant: 'error',
              message: 'Failed to upload file',
            });
            return Promise.reject();
          });
        }

        // Do some refreshing of page here
        dispatch.site.getSite(roomId);

        return { success: true };
      } catch (error) {
        return { success: false };
      }
    },
    async fileDelete(id: string, rootState: any) {
      const { orgId, roomId } = rootState.app;

      try {
        await api.delete(`/orgs/${orgId}/rooms/${roomId}/items/${id}`, {
          deleteItem: true,
          itemId: id,
        });

        await dispatch.site.getSite(roomId);

        return { success: true };
      } catch (error) {
        console.error(error);
        return { success: false };
      }
    },
    async actionClick({ id, data }: any, rootState: any) {
      const { roomId } = rootState.app;
      const { id: siteId } = rootState.site;

      try {
        const payload = { ...data, siteId };

        await api.post(`/rooms/${roomId}/items/${id}/actions`, payload);

        // Should we do something like the file upload? Wherein we'll refetch all plugins list?
        await dispatch.site.getSite(roomId);

        return { success: true };
      } catch (error) {
        console.error(error);
        return { success: false };
      }
    },
    async sendESign({ id: itemid, data }: any, rootState: any) {
      const {
        orgId: orgid,
        roomId: roomid,
        memberId: memberid,
      } = rootState.app;
      const { id } = rootState.site;

      try {
        const payload = {
          orgid,
          roomid,
          memberid,
          itemid,
          id,
          signature: { body: data.body },
        };

        const response = await api.post(`/esign`, payload);

        // Seems like it's best that we always re-fetch the site?
        await dispatch.site.getSite(roomid);

        return { success: !!response.success };
      } catch (error) {
        console.error(error);
        return { success: false };
      }
    },
  };
}
