import React from 'react';
import Tabs from '@salesforce/design-system-react/lib/components/tabs';
import TabsPanel from '@salesforce/design-system-react/lib/components/tabs/panel';
import Input from '@salesforce/design-system-react/lib/components/input';
import Combobox from '@salesforce/design-system-react/lib/components/combobox';
import TextArea from '@salesforce/design-system-react/lib/components/textarea';
import { CardElement } from '@stripe/react-stripe-js';

import './PaymentForm.scss';

interface Props {
  hasStripeKey: boolean;
  methods: string[];
  values?: any;
  onFieldsChange: (field: string, value: string) => void;
  onTabChange: (index: number) => void;
  onComboboxChange: (field: string, data: any) => void;
}

const STRIPE_STYLE = {
  base: {
    color: '#32325d',
    fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
    fontSmoothing: 'antialiased',
    fontSize: '16px',
    '::placeholder': {
      color: '#aab7c4',
    },
  },
  invalid: {
    color: '#fa755a',
    iconColor: '#fa755a',
  },
};

const bankAccountTypes = [
  { id: 'Checking', label: 'Checking' },
  { id: 'Savings', label: 'Savings' },
  { id: 'Business Checking', label: 'Business Checking' },
];
const accountHolderTypes = [
  { id: 'Individual', label: 'Individual' },
  { id: 'Company', label: 'Company' },
];

function PaymentForm({
  hasStripeKey,
  methods,
  values,
  onFieldsChange,
  onTabChange,
  onComboboxChange,
}: Props) {
  function handleFieldChange(field: string) {
    return (event: any) => onFieldsChange(field, event.target.value);
  }

  function handleComboboxChange(field: string) {
    return (event: any, data: any) => onComboboxChange(field, data);
  }

  if (methods.length > 1) {
    const tabs = methods.map((method) => {
      switch (method) {
        case 'ach':
          return (
            <TabsPanel label="Bank Transfer" key="ach-payment-method">
              <Input
                label="Bank Name"
                onChange={handleFieldChange('bank_name')}
              />
              <Input
                label="Bank Routing Number"
                onChange={handleFieldChange('routing_number')}
              />
              <Input
                label="Checking Account Number"
                onChange={handleFieldChange('account_number')}
              />
              <Combobox
                labels={{ label: 'Bank Account Type' }}
                value={values.bank_account_type || 'Business Checking'}
                selection={
                  values && values.bank_account_type
                    ? [
                        {
                          id: values.bank_account_type,
                          label: values.bank_account_type,
                        },
                      ]
                    : [{ id: 'Business Checking', label: 'Business Checking' }]
                }
                options={bankAccountTypes}
                variant="readonly"
                events={{
                  onSelect: handleComboboxChange('bank_account_type'),
                }}
              />
              <Combobox
                labels={{ label: 'Account Holder Type' }}
                value={values.account_holder_type || 'Company'}
                selection={
                  values && values.account_holder_type
                    ? [
                        {
                          id: values.account_holder_type,
                          label: values.account_holder_type,
                        },
                      ]
                    : [{ id: 'Company', label: 'Company' }]
                }
                options={accountHolderTypes}
                variant="readonly"
                events={{
                  onSelect: handleComboboxChange('account_holder_type'),
                }}
              />
            </TabsPanel>
          );

        case 'po':
          return (
            <TabsPanel label="Purchase Order" key="po-payment-method">
              <Input
                label="Purchase Order Number"
                onChange={handleFieldChange('purchaseOrderNumber')}
              />
              <TextArea
                label="PO Instructions"
                onChange={handleFieldChange('poInstructions')}
              />
            </TabsPanel>
          );

        case 'card':
        default:
          return (
            <TabsPanel label="Credit Card" key="card-payment-method">
              {hasStripeKey ? (
                <div>
                  <CardElement options={{ style: STRIPE_STYLE }} />
                </div>
              ) : (
                <div className="slds-text-color_error slds-m-vertical_small slds-text-align_center">
                  Error: Cannot display credit card field. Missing Publishable
                  API Key.
                </div>
              )}
            </TabsPanel>
          );
      }
    });

    return (
      <Tabs className="payment-form" onSelect={onTabChange}>
        {tabs}
      </Tabs>
    );
  }

  return hasStripeKey ? (
    <div className="slds-m-bottom_medium">
      <CardElement options={{ style: STRIPE_STYLE }} />
    </div>
  ) : (
    <div className="slds-text-color_error slds-m-vertical_small slds-text-align_center">
      Error: Cannot display credit card field. Missing Publishable API Key.
    </div>
  );
}

export default PaymentForm;
