import React, { useState, useEffect, useCallback } from 'react';
import uniqueId from 'lodash/uniqueId';

import Icon from '@salesforce/design-system-react/lib/components/icon';
import Alert from '@salesforce/design-system-react/lib/components/alert';
import AlertContainer from '@salesforce/design-system-react/lib/components/alert/container';
import Toast from '@salesforce/design-system-react/lib/components/toast';
import ToastContainer from '@salesforce/design-system-react/lib/components/toast/container';

import ToastyManager from './ToastyManager';

function Toasty() {
  const [items, setItems] = useState<any[]>([]);
  const [toasts, setToasts] = useState<any[]>([]);

  useEffect(() => {
    function cb(args: any) {
      switch (args.type) {
        case 'alert':
          setItems([...items, args]);
          break;

        case 'clear-toasts':
          setToasts([]);
          break;

        case 'pop-toast':
          setToasts(toasts.slice(0, toasts.length - 1));
          break;

        case 'delete-toast': {
          const index = toasts.findIndex((toast) => toast.ident === args.ident);

          if (index !== -1) {
            setToasts([
              ...toasts.slice(0, index),
              ...toasts.slice(index + 1, toasts.length),
            ]);
          }
          break;
        }

        default:
          setToasts([...toasts, args]);
      }
    }

    ToastyManager.on(cb);

    return () => ToastyManager.off(cb);
  }, [items, toasts]);

  const handleRequestClose = useCallback(
    (index) => () => {
      if (items[index]) {
        setItems([
          ...items.slice(0, index),
          ...items.slice(index + 1, items.length),
        ]);
      } else if (toasts[index]) {
        setToasts([
          ...toasts.slice(0, index),
          ...toasts.slice(index + 1, toasts.length),
        ]);
      }
    },
    [items, toasts]
  );

  return (
    <div>
      <AlertContainer>
        {items.map((item, index) => (
          <Alert
            key={`alert-${item.variant}-${index}-${uniqueId('toast_')}`}
            icon={<Icon category="utility" name={item.variant} />}
            dismissible
            labels={{
              heading: item.message,
            }}
            variant={item.variant}
            onRequestClose={handleRequestClose(index)}
          />
        ))}
      </AlertContainer>
      <ToastContainer>
        {toasts.map((item, index) => (
          <Toast
            key={`toast-${item.variant}-${index}-${uniqueId('toast_')}`}
            labels={{
              heading: item.message,
            }}
            variant={item.variant}
            onRequestClose={handleRequestClose(index)}
            duration={5000}
          />
        ))}
      </ToastContainer>
    </div>
  );
}

export default Toasty;
